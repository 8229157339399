<!-- 项目详情 -->
<template>
  <div class="projectContent">
    <!-- 头部 -->
    <div class="project">
      <!-- 项目详情介绍信息 -->
      <el-descriptions title="项目详情"
                       :column=column>
        <el-descriptions-item label="项目编号">{{projectDetailsList.id}}</el-descriptions-item>
        <el-descriptions-item label="项目名称">{{projectDetailsList.name}}</el-descriptions-item>
        <el-descriptions-item label="项目类型">
          <el-tag size="small">{{projectDetailsList.type==1?'LDT':projectDetailsList.type==2?'CRO':'RES'}}</el-tag>
        </el-descriptions-item>
        <el-descriptions-item v-if="projectDetailsList.endTime"
                              label="项目预期结束时间">{{projectDetailsList.endTime}}</el-descriptions-item>
        <el-descriptions-item v-if="projectDetailsList.username"
                              label="项目负责人">{{projectDetailsList.username}}</el-descriptions-item>
        <el-descriptions-item v-if="projectDetailsList.phone"
                              label="负责人手机">{{projectDetailsList.phone}}</el-descriptions-item>
        <el-descriptions-item v-if="projectDetailsList.email"
                              label="负责人邮箱">{{projectDetailsList.email}}</el-descriptions-item>
        <el-descriptions-item label="项目简介">{{projectDetailsList.introduction}}</el-descriptions-item>
      </el-descriptions>
      <!-- 操作按钮 -->
      <div class="btn">
        <el-button @click="back">返回项目列表</el-button>
        <el-button v-if="jurisdiction!=1"
                   type="success"
                   @click="openAddDialogVisible"><i class="el-icon-plus"></i>添加样本</el-button>
        <el-button v-if="jurisdiction==3"
                   type="primary"
                   @click="permission"><i class="el-icon-setting"></i>分配权限</el-button>
        <el-button v-if="jurisdiction!=1"
                   type="success"
                   @click="openSampleBox()"><i class="el-icon-upload"></i>批量导入样本</el-button>
        <el-button v-if="projectDetailsList.isExport"
                   type="warning"
                   @click="exportExcel()"><i class="el-icon-download"></i>导出项目</el-button>
        <el-button v-if="jurisdiction!=1"
                   type="success"
                   @click="openExperimentalData()"><i class="el-icon-upload"></i>导入实验信息</el-button>
        <el-button v-if="jurisdiction==3"
                   type="primary"
                   @click="addPrincipal()"><i class="el-icon-edit"></i>修改项目信息</el-button>
        <el-button v-if="identity==1||jurisdiction==3"
                   type="danger"
                   @click="delProject()"><i class="el-icon-delete"></i>删除项目</el-button>
      </div>
    </div>

    <!-- 添加样本对话框 -->
    <template>
      <el-dialog title="添加样本"
                 :visible.sync="addSampleDialogVisible"
                 width="50%">
        <div class="demo-input-suffix">
          <el-form :model="addSamplelist"
                   ref="addSamplelist"
                   :rules="rules"
                   label-width="125px">
            <el-form-item label="样本编号"
                          key="customerNumber"
                          prop="customerNumber">
              <el-input v-model="addSamplelist.customerNumber"
                        placeholder="请输入样本编号"></el-input>
            </el-form-item>
            <el-form-item v-if="projectDetailsList.type==1"
                          label="试管编号"
                          key="number">
              <el-input v-model="addSamplelist.number"
                        placeholder="请输入试管编号"></el-input>
            </el-form-item>
            <el-form-item label="客户名称"
                          key="customerName"
                          prop="customerName">
              <el-input v-model="addSamplelist.customerName"
                        placeholder="请输入客户名称"></el-input>
            </el-form-item>
            <el-form-item v-if="projectDetailsList.type==1"
                          label="客户性别"
                          key="gender">
              <el-radio-group v-model="addSamplelist.gender">
                <el-radio label=0>男</el-radio>
                <el-radio label=1>女</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item v-if="projectDetailsList.type==1"
                          label="客户年龄"
                          key="age">
              <el-input v-model="addSamplelist.age"
                        placeholder="请输入客户年龄"></el-input>
            </el-form-item>
            <el-form-item label="样本类型"
                          key="type"
                          prop="type">
              <el-select size="medium"
                         v-model="addSamplelist.type"
                         placeholder="请选择类型">
                <el-option label="动物组织"
                           value="1"></el-option>
                <el-option label="植物组织"
                           value="2"></el-option>
                <el-option label="血液"
                           value="3"></el-option>
                <el-option label="细菌"
                           value="4"></el-option>
                <el-option label="细胞"
                           value="5"></el-option>
                <el-option label="其他"
                           value="6"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="收样日期"
                          key="sampleTime"
                          prop="sampleTime">
              <el-date-picker type="date"
                              placeholder="选择日期"
                              v-model="addSamplelist.sampleTime"
                              style="width: 100%;"></el-date-picker>
            </el-form-item>
            <el-form-item label="预期结束日期"
                          key="endTime"
                          prop="endTime">
              <el-date-picker type="date"
                              placeholder="选择日期"
                              v-model="addSamplelist.endTime"
                              style="width: 100%;"></el-date-picker>
            </el-form-item>
            <el-form-item v-if="projectDetailsList.type==1"
                          label="产品名称"
                          prop="productId">
              <el-select v-model="addSamplelist.productId"
                         placeholder="请选择产品名称">
                <el-option v-for="item in identityList"
                           :key="item.id"
                           :label="item.name"
                           :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item v-if="projectDetailsList.type==1"
                          label="样本来源"
                          prop="source"
                          key="source">
              <el-select v-model="addSamplelist.source"
                         filterable
                         allow-create
                         default-first-option
                         placeholder="请选择样本来源">
                <el-option v-for="item in sourceList"
                           :key="item.id"
                           :label="item.name"
                           :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="数据量"
                          v-if="projectDetailsList.type==1"
                          key="dataSize">
              <el-input v-model="addSamplelist.dataSize"
                        placeholder="请输入数据量"></el-input>
            </el-form-item>
            <el-form-item label="样本批次"
                          prop="batch">
              <el-select size="medium"
                         v-model="addSamplelist.batch"
                         placeholder="请选择样本批次">
                <el-option label="1"
                           value="1"></el-option>
                <el-option label="2"
                           value="2"></el-option>

              </el-select>
            </el-form-item>
            <el-form-item v-if="projectDetailsList.type==1"
                          label="知情同意书">
              <el-upload action="/spl/sample/addSample"
                         ref="informedUpload"
                         :headers="headers"
                         multiple
                         :data='addSamplelist'
                         :auto-upload="false"
                         list-type="picture-card"
                         :on-remove="handleRemove"
                         :on-change="hanlechange">
                <i class="el-icon-plus"></i>
              </el-upload>

            </el-form-item>
            <el-form-item label="备注"
                          prop="introduction">
              <el-input v-model="addSamplelist.remarks"
                        type="textarea"
                        placeholder="请输入备注"></el-input>
            </el-form-item>
            <el-form-item>
              <el-checkbox v-model="checked">继续添加</el-checkbox>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer"
              class="dialog-footer">
          <el-button @click="addSampleDialogVisible = false">取 消</el-button>
          <el-button type="success"
                     :disabled="buyFlag"
                     @click="submitSample('addSamplelist')">提交</el-button>
        </span>
      </el-dialog>
    </template>

    <!-- 导入实验信息 -->
    <template>
      <el-dialog title="导入/更新实验"
                 :visible.sync="importExperimentInfo"
                 width="55%">
        <div class="demo-input-suffix">
          <el-form :model="addExperimentProcesslist"
                   ref="addExperimentProcesslist"
                   :rules="importTemplateRules"
                   label-width="125px">
            <div style=" display: flex;">
              <el-form-item label="实验流程模板"
                            prop="processName">
                <el-select size="medium"
                           @change='experimentList'
                           v-model="addExperimentProcesslist.processName"
                           placeholder="请选择流程">
                  <el-option v-for="(item,index) in experimentalProcedureData"
                             :key="item.id"
                             :label="item.name"
                             :value="index"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item v-if="showExperimentProcess"
                            label="导入/更新步骤"
                            prop="indexList">
                <el-checkbox :indeterminate="isIndeterminate"
                             v-model="checkAll"
                             @change="handleCheckAllChange">全选</el-checkbox>
                <el-checkbox-group v-model="addExperimentProcesslist.indexList">
                  <div style="height: 30px;"
                       v-for="(item,index) in stageNameList"
                       :key="index">
                    <el-checkbox :label="item">
                      {{index+1+'.'+item}}
                    </el-checkbox>
                  </div>
                </el-checkbox-group>
              </el-form-item>
            </div>
            <el-form-item label="导入实验文件"
                          prop="introduction">
              <el-upload class="upload-demo"
                         name="report"
                         ref="upload"
                         multiple
                         action="#"
                         :limit=InfoCatalogUserBlock
                         :on-change="fileChange"
                         :headers="headers"
                         :auto-upload="false">
                <el-button slot="trigger"
                           size="small"
                           type="primary">选择文件</el-button>
              </el-upload>
              <div style="color:coral">1.导入的样本必须提前创建，若实验步骤中包含图片请手动上传。</div>
              <div style="color:coral">2.最少导入一条样本的信息，客户编号必填，否则不能导入数据。</div>
              <div style="color:coral">3.若修改了实验流程模板，须重新下载模板才能导入。</div>
              <div style="color:coral">4.文件必须为以xlsx，xls结尾的excel表格，且文件不能超过20M。</div>
              <div style="color:coral">5.只能导入当前选择的实验流程或实验步骤，若样本已有实验流程则会进行更新操作。</div>
            </el-form-item>
          </el-form>

          <div>
            <el-link type="primary"
                     @click="downloadExperimentInfo">下载模板</el-link>
          </div>

        </div>
        <span slot="footer"
              class="dialog-footer">
          <el-button @click="importExperimentInfo = false">取 消</el-button>
          <el-button type="success"
                     :disabled="buyFlag"
                     @click="importExperimentExcel('addExperimentProcesslist')">提交</el-button>
        </span>
      </el-dialog>
    </template>
    <!-- 批量导入样本 -->
    <template>
      <el-dialog title="批量导入样本"
                 :visible.sync="bulkImportSample"
                 width="50%">
        <div class="demo-input-suffix">
          <el-form ref="addSamplelist"
                   label-width="125px">
            <el-form-item label="导入样本"
                          prop="introduction">
              <el-upload class="upload-demo"
                         name="report"
                         ref="upload2"
                         multiple
                         action="#"
                         :limit=InfoCatalogUserBlock
                         :on-change="sampleFileChange"
                         :headers="headers"
                         :auto-upload="false">
                <el-button slot="trigger"
                           size="small"
                           type="primary">选择文件</el-button>
              </el-upload>
              <div style="color:coral">1.样本列表不能为空，且样本编号不能与已有样本重复</div>
              <div style="color:coral">2.样本信息除备注外不能为空，日期格式如：20230222</div>
              <div style="color:coral">3.文件必须为以xlsx，xls结尾的excel表格，且文件不能超过20M。</div>
              <div style="color:coral">4.如果模版中没有对应的来源或产品，请联系管理员添加来源或产品。</div>

            </el-form-item>
          </el-form>
          <div>
            <el-link type="primary"
                     @click="downloadSampleTemplate">下载模板</el-link>
          </div>
        </div>
        <span slot="footer"
              class="dialog-footer">
          <el-button @click="bulkImportSample = false">取 消</el-button>
          <el-button type="success"
                     :disabled="buyFlag"
                     @click="importSampleExcel()">提交</el-button>
        </span>
      </el-dialog>
    </template>

    <!-- 修改项目信息 -->
    <template>
      <el-dialog title="修改项目详情信息"
                 :visible.sync="modificationDialogVisible"
                 width="50%">
        <div class="demo-input-suffix">
          <el-form :model="modifyProjectInfolist"
                   ref="modifyProjectInfolist"
                   :rules="rules1"
                   label-width="135px">
            <el-form-item label="项目名"
                          key="name">
              <el-input v-model="modifyProjectInfolist.name"
                        placeholder="请输入项目名"></el-input>
            </el-form-item>
            <el-form-item label="项目负责人"
                          prop="commander">
              <el-select v-model="modifyProjectInfolist.commander"
                         clearable
                         placeholder="请选择项目负责人">
                <el-option v-for="item in userList"
                           :key="item.id"
                           :label="item.username"
                           :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="项目类型">
              <el-select v-model="modifyProjectInfolist.type">
                <el-option label='LDT'
                           :value=1></el-option>
                <el-option label='CRO'
                           :value=2></el-option>
                <el-option label='RES'
                           :value=3></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="预期结束日期">
              <el-date-picker type="date"
                              placeholder="选择日期"
                              v-model="modifyProjectInfolist.endTime"
                              style="width: 100%;"></el-date-picker>
            </el-form-item>
            <el-form-item label="项目简介"
                          prop="introduction">
              <el-input v-model="modifyProjectInfolist.introduction"
                        type="textarea"
                        placeholder="请输入项目简介"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer"
              class="dialog-footer">
          <el-button @click="modificationDialogVisible = false">取 消</el-button>
          <el-button type="success"
                     :disabled=modification
                     @click="modifyProjectInfo('modifyProjectInfolist')">提交</el-button>
        </span>
      </el-dialog>
    </template>

    <!-- 样本列表 -->
    <h1 class="sampleList">样本列表</h1>
    <el-divider></el-divider>
    <el-form :model="queryParams"
             ref="queryParams"
             size="small"
             :inline="true"
             label-width="78px">
      <el-form-item>
        <el-button type="warning"
                   size="medium"
                   @click="handle">批量跳转样本</el-button>
      </el-form-item>
      <el-form-item label="样本编号/客户名称"
                    label-width="140px">
        <el-input siz="medium"
                  style="width:200px;"
                  v-model="queryParams.keyword"
                  clearable
                  @input='getProjectInfo()'
                  placeholder="请输入样本编号/客户名称"></el-input>
      </el-form-item>

      <el-form-item>
        <el-button type="primary"
                   size="medium"
                   icon="el-icon-search"
                   @click="getProjectInfo()">搜索</el-button>
      </el-form-item>
      <el-button v-if="DerivedSample"
                 type="success"
                 @click="exportExcelSample()"><i class="el-icon-download"></i>导出已选样本</el-button>
      <el-button v-if="!DerivedSample"
                 type="warning"
                 @click="exportExcelAllSample()"><i class="el-icon-download"></i>导出全部样本</el-button>
    </el-form>

    <el-card class="box-card">
      <!-- 数据表格 -->
      <div id="baseButton">
        <BaseTable :has-index="true"
                   :has-border="false"
                   :table-data="tableData"
                   :loading="loading"
                   :table-data-total="tableData.length"
                   :table-column-options="tableColumnOptions"
                   @selectionChange="selectionChange"
                   @filterChange="filterChange">
          <!-- 数据交付 -->
          <template #isConfirm="{ data }">
            <div class="tag">
              <el-tag type="success"
                      size="mini"
                      v-if="data.isConfirm == 1">已交付</el-tag>
              <el-tag type="danger"
                      size="mini"
                      v-if="data.isConfirm == 0">未交付</el-tag>
            </div>
          </template>
          <!-- 来源 -->
          <template #source="{ data }">
            <div class="tag">
              <span>{{data.source}}</span>
            </div>
          </template>
          <!-- 产品类型 -->
          <template #productName="{ data }">
            <div class="tag"
                 v-if="projectDetailsList.type==1&&data.informedConsent!=null">
              <span class="link"
                    @click="opneInformed(data.informedConsent)">{{data.productName}}</span>
            </div>
            <div class="tag"
                 v-else>
              <span>{{data.productName}}</span>
            </div>
          </template>

          <!-- 操作插槽 -->
          <template #operation="{ data }">
            <el-button v-if="data.isConfirm==0&&data.isFinishProcess&&projectDetailsList.type==1"
                       type="text"
                       size="mini"
                       icon="el-icon-tickets"
                       @click="sampleAccomplish(data.id)"
                       class="affirm">确认交付</el-button>
            <el-button type="text"
                       size="mini"
                       icon="el-icon-edit"
                       @click="openSampleInfo(data)">编辑</el-button>
          </template>
          <!-- 客户编号插槽 -->
          <template #link="{ data }">
            <span class="link"
                  @click="updateHandler(data.customerNumber)">{{data.customerNumber}}</span>
          </template>
          <!-- 客户姓名插槽 -->
          <template #customerName="{ data }">
            <el-popover v-if="projectDetailsList.type==1&&(data.age!=null||data.gender!=null)"
                        trigger="hover"
                        placement="top">
              <p>性别: {{ data.gender==0?'男':data.gender==1?'女':'未知' }}</p>
              <p>年龄: {{ data.age }}</p>
              <div slot="reference"
                   class="name-wrapper">
                <el-tag size="mini">{{ data.customerName }}</el-tag>
              </div>
            </el-popover>
            <div v-else> {{ data.customerName }} </div>
          </template>
          <!-- 肺癌插槽 -->
          <template #lungCancerResult="{ data }">
            <span v-if="data.lungCancerResult!=null">
              <span :class="data.lungCancerResult.search('阳')!=-1?'obvious':''">{{data.lungCancerResult}}</span>
            </span>
            <span v-else>
              <span>{{data.lungCancerResult}}</span>
            </span>
          </template>
          <!-- 肝癌插槽 -->
          <template #liverCancerResult="{ data }">
            <span v-if="data.liverCancerResult!=null">
              <span :class="data.liverCancerResult.search('阳')!=-1?'obvious':''">{{data.liverCancerResult}}</span>
            </span>
            <span v-else>
              <span>{{data.liverCancerResult}}</span>
            </span>
          </template>
          <!-- 备注插槽 -->
          <template #remarks="{ data }">
            <span class="remarks">{{data.remarks}}</span>
          </template>
          <template #dataSize="{ data }">
            <span>{{data.dataSize}}</span>
          </template>
          <template #batch="{ data }">
            <span>{{data.batch}}</span>
          </template>
        </BaseTable>
      </div>
      <!-- 分页导航 -->
      <template>
        <el-row class="pagination">
          <pagination v-show="total>0"
                      :total="total"
                      :pageSizes=pageSizes
                      :page.sync=queryParams.pageNum
                      :limit.sync=queryParams.pageSize
                      @pagination="getProjectInfo" />
        </el-row>
      </template>
    </el-card>

    <!-- 编辑按钮 -->
    <template>
      <el-dialog title="修改样本信息"
                 :visible.sync="sampleInfo"
                 width="50%">
        <div class="demo-input-suffix">
          <el-form :model="modifySampleInfoList"
                   ref="modifySampleInfoList"
                   label-width="135px">
            <el-form-item label="结束时间"
                          prop="endTime">
              <el-date-picker type="date"
                              :clearable=false
                              placeholder="选择日期"
                              v-model="modifySampleInfoList.endTime"
                              style="width: 100%;"></el-date-picker>
            </el-form-item>
            <el-form-item label="样本编号"
                          prop="newId">
              <el-input v-model="modifySampleInfoList.newId"
                        placeholder="请输入新样本编号"></el-input>
            </el-form-item>
            <el-form-item v-if="projectDetailsList.type==1"
                          label="试管编号"
                          prop="newId">
              <el-input v-model="modifySampleInfoList.number"
                        placeholder="请输入新试管编号"></el-input>
            </el-form-item>
            <el-form-item label="客户名称"
                          prop="customerName">
              <el-input v-model="modifySampleInfoList.customerName"
                        placeholder="请输入客户名称"></el-input>
            </el-form-item>
            <el-form-item v-if="projectDetailsList.type==1"
                          label="客户性别"
                          key="gender">
              <el-radio-group v-model="modifySampleInfoList.gender">
                <el-radio v-model="modifySampleInfoList.gender"
                          :label="0">男</el-radio>
                <el-radio v-model="modifySampleInfoList.gender"
                          :label="1">女</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item v-if="projectDetailsList.type==1"
                          label="客户年龄"
                          key="age">
              <el-input v-model="modifySampleInfoList.age"
                        placeholder="请输入客户年龄"></el-input>
            </el-form-item>
            <el-form-item label="收样时间"
                          prop="sampleTime">
              <el-date-picker type="date"
                              :clearable=false
                              placeholder="选择日期"
                              v-model="modifySampleInfoList.sampleTime"
                              style="width: 100%;"></el-date-picker>
            </el-form-item>
            <el-form-item v-if="projectDetailsList.type==1"
                          label="产品名称">
              <el-select v-model="modifySampleInfoList.productId"
                         placeholder="">
                <el-option v-for="item in identityList"
                           :key="item.id"
                           :label="item.name"
                           :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item v-if="projectDetailsList.type==1"
                          label="数据量"
                          prop="source">
              <el-input v-model="modifySampleInfoList.dataSize"
                        placeholder="请输入样本数据量"></el-input>
            </el-form-item>
            <el-form-item v-if="projectDetailsList.type==1"
                          label="来源"
                          prop="source">
              <el-select v-model="modifySampleInfoList.source"
                         filterable
                         allow-create
                         default-first-option
                         placeholder="请选择样本来源">
                <el-option v-for="item in sourceList"
                           :key="item.id"
                           :label="item.name"
                           :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="样本批次"
                          key="type">
              <el-select size="medium"
                         v-model="modifySampleInfoList.batch"
                         placeholder="请选择类型">
                <el-option label="1"
                           value="1"></el-option>
                <el-option label="2"
                           value="2"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item v-if="projectDetailsList.type==1"
                          label="知情同意书">
              <el-upload action="/spl/sample/addSample"
                         ref="informedUpload"
                         :headers="headers"
                         multiple
                         :data='addSamplelist'
                         :auto-upload="false"
                         list-type="picture-card"
                         :file-list="redactFileList"
                         :on-remove="redactHandleRemove"
                         :on-change="redactHanlechange">
                <i class="el-icon-plus"></i>
              </el-upload>

            </el-form-item>
            <el-form-item label="备注"
                          prop="introduction">
              <el-input v-model="modifySampleInfoList.remarks"
                        type="textarea"
                        placeholder="请输入备注信息"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer"
              class="dialog-footer">
          <el-button v-if="identity==1||this.jurisdiction==3"
                     type='danger'
                     @click="delSample()">删除样本</el-button>
          <el-button @click="sampleInfo = false">取 消</el-button>
          <el-button type="success"
                     :disabled=modification
                     @click="modifySampleInfo()">提交</el-button>
        </span>
      </el-dialog>
    </template>

  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import BaseTable from '@/components/BaseTable'
export default {
  components: {
    Pagination,
    BaseTable
  },
  data() {
    return {
      checkAll: false,
      isIndeterminate: true,
      fileList: [],
      redactFileList: [],
      fileSourceList: [],
      // 上传文件/图片请求消息头
      headers: {
        token: sessionStorage.getItem('token')
      },
      // 控制添加样本对话框
      addSampleDialogVisible: false,
      // 添加样本继续添加框
      checked: false,
      // 修改样本信息弹出框
      sampleInfo: false,
      // 控制导入实验信息对话框
      importExperimentInfo: false,
      //批量导入样本
      bulkImportSample: false,
      // 获取负责人数组
      userList: [],
      total: 0,
      pageSizes: [50, 100, 200, 400],
      queryParams: {
        id: '',
        pageNum: 1,
        pageSize: 50,
        liverCancerResultArr: [],
        lungCancerResultArr: [],
        isConfirmArr: '',
        productIdArr: [],
        keyword: '',
        sourceIdArr: []
      },
      // 修改样本信息入参
      modifySampleInfoList: {
        id: '',
        remarks: '',
        endTime: '',
        source: '',
        newId: '',
        sampleTime: '',
        customerName: '',
        productId: '',
        rateList: [],
        dataSize: '',
        batch: '',
        age: '',
        gender: '',
        informedConsent: '',
        number: ''
      },
      // 项目详情信息
      projectDetailsList: {},
      pageNum: '',
      keyword: '',
      type: '',
      modificationDialogVisible: false,
      // 添加样本入参
      addSamplelist: {
        type: '',
        sampleTime: '',
        remarks: '',
        projectId: '',
        customerName: '',
        customerNumber: '',
        endTime: '',
        productId: '',
        source: '',
        dataSize: '',
        batch: 1,
        age: '',
        gender: '',
        informedConsent: '',
        number: ''
      },
      identityList: [],
      sourceList: [],
      // 修改项目详情信息入参
      modifyProjectInfolist: {
        name: '',
        id: '',
        introduction: ' ',
        commander: ' ',
        endTime: '',
        type: ''
      },
      // 表单验证
      rules: {
        sampleTime: [{ type: 'date', required: true, message: '请选择日期', trigger: 'change' }],
        customerNumber: [{ required: true, message: '请输入客户编号', trigger: 'change' }],
        type: [{ required: true, message: '请选择样本类型', trigger: 'change' }],
        batch: [{ required: true, message: '请选择样本批次', trigger: 'change' }],
        productId: [{ required: true, message: '请选择产品名称', trigger: 'change' }],
        customerName: [{ required: true, message: '请输入客户姓名', trigger: 'change' }],
        name: [{ required: true, message: '请输入项目名称', trigger: 'change' }],
        endTime: [{ required: true, message: '请选择日期', trigger: 'change' }],
        source: [{ required: true, message: '请输入样本来源', trigger: 'change' }]
      },
      rules1: {
        commander: [{ required: true, message: '请选择项目负责人', trigger: 'change' }]
      },
      importTemplateRules: {
        processName: [{ required: true, message: '请选择实验流程模板', trigger: 'change' }],
        indexList: [{ type: 'array', required: true, message: '请至少选择一个实验步骤', trigger: 'change' }]
      },
      // 表格数据
      tableData: [],
      buyFlag: false,
      modification: false,
      multipleSelection: [],
      sampleIdArray: '',
      identity: '',
      uploadingSample: { file: '' },
      // 导入上传数量
      InfoCatalogUserBlock: 1,
      // 项目详情权限
      jurisdiction: '',
      projectId: '',
      // 表头
      tableColumnOptions: [],
      // 表格数据
      tableData: [],
      // 控制加载中
      loading: true,
      //控制导出多选按钮
      DerivedSample: false,
      column: 4,
      pageSizeParticulars: '',
      pageNumParticulars: '',
      formDataSamplelist: {
        type: '',
        sampleTime: '',
        remarks: '',
        projectId: '',
        customerName: '',
        customerNumber: '',
        endTime: '',
        productId: '',
        source: '',
        dataSize: '',
        batch: 1,
        age: '',
        gender: '',
        informedConsent: ''
      },
      // 实验流程数据
      experimentalProcedureData: [],
      stageNameList: [],
      // 添加实验流程入参
      addExperimentProcesslist: {
        processName: '',
        indexList: []
      },
      showExperimentProcess: false
    }
  },
  created() {
    if (this.$route.query.pageNumParticulars != 1 && this.$route.query.pageSizeParticulars != undefined) {
      this.queryParams.pageNum = Number(this.$route.query.pageNumParticulars)
      this.queryParams.pageSize = Number(this.$route.query.pageSizeParticulars)
    } else {
      this.queryParams.pageNum = 1
      this.queryParams.pageSize = 50
    }
  },
  mounted() {
    this.getProductList()
    this.getSourceList()
    this.projectId = this.$route.query.id
    this.getProjectAuth(this.projectId).then(() => {
      this.identity = window.sessionStorage.getItem('identity')
      this.pageNum = this.$route.query.pageNum
      this.pageSize = this.$route.query.pageSize
      this.keyword = this.$route.query.keyword
      this.type = this.$route.query.type
      if (this.$route.query.id != undefined) {
        window.sessionStorage.setItem('projectID', this.$route.query.id)
        var id = window.sessionStorage.getItem('projectID')
        this.queryParams.id = id
        this.modifyProjectInfolist.id = id
        // 获取详情信息
        this.getProjectInfo()
      } else {
        var id = window.sessionStorage.getItem('projectID')
        this.queryParams.id = id
        this.modifyProjectInfolist.id = id
        // 获取详情信息
        this.getProjectInfo()
      }
    })
  },
  methods: {
    handleCheckAllChange(val) {
      this.addExperimentProcesslist.indexList = val ? this.stageNameList : []
      this.isIndeterminate = false
    },
    opneInformed(img) {
      if (img) {
        window.open(this.$url + img)
      } else {
        this.$message.error('请先上传知情同意书!')
      }
    },
    async getProjectAuth(_id) {
      const { data: res } = await this.$http.get(`/spl/projectAuth/getProjectAuth?projectId=${_id}`)
      this.jurisdiction = res.data.projectAuthValue
      window.sessionStorage.setItem('encryptProjectId', res.data.encryptProjectId)
    },
    // 返回
    back() {
      this.$router.push({ path: '/project', query: { pageNum: this.pageNum, pageSize: this.pageSize, keyword: this.keyword, type: this.type } })
    },
    // 打开添加样本对话框
    async openAddDialogVisible() {
      this.buyFlag = false
      this.addSampleDialogVisible = true
      this.addSamplelist.projectId = this.queryParams.id
    },
    // 获取产品名称列表
    async getProductList() {
      const { data: res } = await this.$http.get(`/spl/product/getProductList`)
      this.identityList = res.data
      res.data.forEach((item) => {
        var obj = { text: item.name, value: item.id }
        this.fileList.push(obj)
      })
    },
    // 获取来源列表
    async getSourceList() {
      const { data: res } = await this.$http.get(`/spl/source/getSourceList`)
      this.sourceList = res.data
      res.data.forEach((item) => {
        var obj = { text: item.name, value: item.id }
        this.fileSourceList.push(obj)
      })
    },
    // 分配权限
    async permission() {
      this.$router.push({ path: '/setPermission', query: { projectId: this.projectId } })
    },
    handleRemove(file, fileList) {
      this.addSamplelist.informedConsent = ''
    },
    hanlechange(file, fileList) {
      this.addSamplelist.informedConsent = file.raw
    },
    redactHandleRemove(file, fileList) {
      this.modifySampleInfoList.informedConsent = ''
    },
    redactHanlechange(file, fileList) {
      this.modifySampleInfoList.informedConsent = file.raw
    },
    // 添加样本提交
    submitSample(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          let addSampleFormData = new FormData()
          addSampleFormData.append('type', this.addSamplelist.type)
          addSampleFormData.append('sampleTime', this.dateFormat(this.addSamplelist.sampleTime))
          addSampleFormData.append('remarks', this.addSamplelist.remarks)
          addSampleFormData.append('projectId', this.addSamplelist.projectId)
          addSampleFormData.append('customerNumber', this.addSamplelist.customerNumber)
          addSampleFormData.append('customerName', this.addSamplelist.customerName)
          addSampleFormData.append('endTime', this.dateFormat(this.addSamplelist.endTime))
          addSampleFormData.append('productId', this.addSamplelist.productId)
          addSampleFormData.append('source', this.addSamplelist.source)
          addSampleFormData.append('dataSize', this.addSamplelist.dataSize)
          addSampleFormData.append('batch  ', this.addSamplelist.batch)
          addSampleFormData.append('age', this.addSamplelist.age)
          addSampleFormData.append('gender', this.addSamplelist.gender)
          addSampleFormData.append('file', this.addSamplelist.informedConsent)
          addSampleFormData.append('number', this.addSamplelist.number)
          this.buyFlag = true
          const { data: res } = await this.$http.post(`/spl/sample/addSample`, addSampleFormData)
          if (res.code == 200) {
            this.getProjectInfo()
            this.getSourceList()
            this.$message.success('添加成功')
          } else {
            this.$message.error(res.msg)
          }
          this.$refs.informedUpload.clearFiles()
          if (this.checked) {
            this.buyFlag = false
            this.addSampleDialogVisible = true
            this.addSamplelist.customerNumber = ''
            this.addSamplelist.customerName = ''
          } else {
            this.addSamplelist = this.formDataSamplelist
            this.addSampleDialogVisible = false
            this.getProjectInfo()
          }
        }
      })
    },
    // 打开批量导入样本对话框
    openSampleBox() {
      this.bulkImportSample = true
    },
    dateFormat(dateData) {
      var date = new Date(dateData)
      var y = date.getFullYear()
      var m = date.getMonth() + 1
      m = m < 10 ? '0' + m : m
      var d = date.getDate()
      d = d < 10 ? '0' + d : d
      const time = y + '-' + m + '-' + d
      return time
    },
    async importSampleExcel() {
      let fileFormData = new FormData()
      fileFormData.append('file', this.uploadingSample.file)
      fileFormData.append('projectId', this.projectDetailsList.id)
      console.log(fileFormData)
      const { data: res } = await this.$http.post(`/spl/sample/importSampleExcel`, fileFormData)
      if (res.code == 200) {
        this.getProjectInfo()
        this.$message.success('导入成功')
      } else {
        this.$message.error(res.msg)
      }
      this.$refs.upload2.clearFiles()
      this.bulkImportSample = false
    },
    // 导出项目
    async exportExcel() {
      const { data: res } = await this.$http.post(`/spl/project/exportExcel`, { projectId: this.queryParams.id }, { responseType: 'blob' })
      const blob = new Blob([res])
      let a = document.createElement('a')
      a.style.display = 'none'
      a.download = `${this.projectDetailsList.name}.xlsx`
      a.href = URL.createObjectURL(blob)
      document.body.appendChild(a)
      a.click()
    },
    // 导出多选样本
    async exportExcelSample() {
      const { data: res } = await this.$http.post(`/spl/project/exportSamples`, { samples: this.multipleSelection }, { responseType: 'blob' })
      const blob = new Blob([res])
      let a = document.createElement('a')
      a.style.display = 'none'
      a.download = `${'samples_export'}.xlsx`
      a.href = URL.createObjectURL(blob)
      document.body.appendChild(a)
      a.click()
    },
    // 导出全部样本
    async exportExcelAllSample() {
      const { data: res } = await this.$http.post(`/spl/project/exportSamples`, { projectId: this.queryParams.id }, { responseType: 'blob' })
      const blob = new Blob([res])
      let a = document.createElement('a')
      a.style.display = 'none'
      a.download = `${'samples_export'}.xlsx`
      a.href = URL.createObjectURL(blob)
      document.body.appendChild(a)
      a.click()
    },
    // 批量导入样本模板
    async downloadSampleTemplate() {
      const { data: res } = await this.$http.get('/spl/project/downloadSampleTemplate', { responseType: 'blob' })
      const blob = new Blob([res])
      let a = document.createElement('a')
      a.style.display = 'none'
      a.download = '批量导入样本模板.xlsx'
      a.href = URL.createObjectURL(blob)
      document.body.appendChild(a)
      a.click()
    },
    // 导入实验信息模版
    async downloadExperimentInfo() {
      const { data: res } = await this.$http.get(`/spl/project/downloadExcelTemplate?projectId=${this.queryParams.id}`, { responseType: 'blob' })
      const blob = new Blob([res])
      let a = document.createElement('a')
      a.style.display = 'none'
      a.download = '实验信息模板.xlsx'
      a.href = URL.createObjectURL(blob)
      document.body.appendChild(a)
      a.click()
    },

    // 打开导入实验信息对话框
    openExperimentalData() {
      this.importExperimentInfo = true
      this.getExperimentProcessTemplate()
    },
    // 查询实验流程模板
    async getExperimentProcessTemplate() {
      const { data: res } = await this.$http.get(`/spl/experiment/getExperimentProcessTemplate`)
      this.experimentalProcedureData = res.data
    },
    // 添加实验流程模板选择
    experimentList(e) {
      this.stageNameList = this.experimentalProcedureData[e].stageNameList
      this.addExperimentProcesslist.processName = this.experimentalProcedureData[e].name
      this.showExperimentProcess = true
      this.addExperimentProcesslist.indexList = []
      this.checkAll = false
    },
    // 上传文件/图片手动确定上传
    importExperimentExcel(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          var points = []
          let proNum = ''
          this.addExperimentProcesslist.indexList.forEach((item1) => {
            proNum = this.stageNameList.findIndex((item, index) => {
              return item == item1
            })
            points.push(proNum + 1)
          })
          // 排序
          points.sort(function (a, b) {
            return a - b
          })
          let fileFormData = new FormData()
          fileFormData.append('file', this.uploadingSample.file)
          fileFormData.append('processName', this.addExperimentProcesslist.processName)
          fileFormData.append('indexList', points)
          const { data: res } = await this.$http.post(`/spl/experiment/importExperimentExcel`, fileFormData)
          if (res.code == 200) {
            this.getProjectInfo()
            this.$message.success('导入成功')
          } else {
            this.$message.error(res.msg)
          }
          this.$refs.upload.clearFiles()
          this.importExperimentInfo = false
        }
      })
    },
    // 下载导入模板
    async downloadExcelTemplate() {
      const { data: res } = await this.$http.get(`/spl/project/downloadExcelTemplate`)
    },
    //获取项目负责人
    async addPrincipal() {
      const { data: res } = await this.$http.get('/spl/user/getUserInfoList')
      this.userList = res.data
      this.modification = false
      this.modificationDialogVisible = true
      this.modifyProjectInfolist.type = this.projectDetailsList.type
    },
    // 修改项目信息详情
    modifyProjectInfo(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.modification = true
          const { data: res } = await this.$http.post(`/spl/project/modifyProject`, this.modifyProjectInfolist)
          if (res.code == 200 && res.data == 1) {
            this.modificationDialogVisible = false
            this.getProjectInfo()
            this.$message.success('修改成功')
          } else {
            this.$message.error(res.msg)
          }
        }
      })
    },
    // 删除项目
    async delProject() {
      const msg = await this.$confirm('你确定要删除该项目吗?此操作将会删除该项目的所有样本及所有实验！', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
      if (msg === 'confirm') {
        //做删除操作
        const { data: res } = await this.$http.get(`/spl/project/delProject?id=${this.queryParams.id}`)
        if (res.code == 200) {
          this.back()
          return this.$message.success('删除成功！')
        } else {
          return this.$message.warning('删除失败')
        }
      }
    },
    // 删除样本
    async delSample() {
      const msg = await this.$confirm('你确定要删除该样本吗?该操作会删除该样本的所有实验!', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
      if (msg === 'confirm') {
        const { data: res } = await this.$http.get(`/spl/sample/delSample?id=${this.modifySampleInfoList.id}`)
        //做删除操作
        if (res.code == 200) {
          this.$message.success('删除样本成功！')
        } else {
          this.$message.warning('删除样本失败')
        }
      }
      this.sampleInfo = false
      this.getProjectInfo()
    },
    // 点击样本编号
    async updateHandler(_id) {
      this.$router.push({ path: '/sampleInfo', query: { id: _id, projectId: this.projectId, type: 2, pageNum: this.queryParams.pageNum, pageSize: this.queryParams.pageSize } })
    },

    // 点击编辑
    openSampleInfo(row) {
      this.redactFileList = []
      this.modifySampleInfoList.newId = row.id
      this.modifySampleInfoList.id = row.id
      this.modifySampleInfoList.source = row.source
      this.modifySampleInfoList.remarks = row.remarks
      this.modifySampleInfoList.endTime = row.endTime
      this.modifySampleInfoList.sampleTime = row.sampleTime
      this.modifySampleInfoList.customerName = row.customerName == undefined ? '' : row.customerName
      this.modifySampleInfoList.productId = row.productId
      this.modifySampleInfoList.dataSize = row.dataSize
      this.modifySampleInfoList.batch = row.batch
      this.modifySampleInfoList.number = row.number == null ? '' : row.number
      this.modifySampleInfoList.age = row.age == null ? '' : row.age
      this.modifySampleInfoList.gender = row.gender == null ? '' : row.gender
      this.modifySampleInfoList.informedConsent = row.informedConsent == null ? '' : row.informedConsent
      this.sampleInfo = true
      this.modification = false
      if (row.informedConsent) {
        let url = this.$url + row.informedConsent
        this.redactFileList.push({
          url: url
        })
      }
    },
    // 修改样本信息
    async modifySampleInfo() {
      if (this.modifySampleInfoList.customerName == '') {
        this.$message.error('客户名称不能为空，请重新输入！')
      } else {
        this.modification = true
        let modifySampleFormData = new FormData()
        modifySampleFormData.append('newId', this.modifySampleInfoList.newId)
        modifySampleFormData.append('sampleTime', this.dateFormat(this.modifySampleInfoList.sampleTime))
        modifySampleFormData.append('id', this.modifySampleInfoList.id)
        modifySampleFormData.append('customerNumber', this.modifySampleInfoList.customerNumber)
        modifySampleFormData.append('customerName', this.modifySampleInfoList.customerName)
        modifySampleFormData.append('endTime', this.dateFormat(this.modifySampleInfoList.endTime))
        modifySampleFormData.append('productId', this.modifySampleInfoList.productId)
        modifySampleFormData.append('source', this.modifySampleInfoList.source)
        modifySampleFormData.append('remarks', this.modifySampleInfoList.remarks)
        modifySampleFormData.append('dataSize', this.modifySampleInfoList.dataSize)
        modifySampleFormData.append('batch  ', this.modifySampleInfoList.batch)
        modifySampleFormData.append('age', this.modifySampleInfoList.age)
        modifySampleFormData.append('gender', this.modifySampleInfoList.gender)
        modifySampleFormData.append('file', this.modifySampleInfoList.informedConsent)
        modifySampleFormData.append('number', this.modifySampleInfoList.number)
        console.log(this.modifySampleInfoList.informedConsent)
        const { data: res } = await this.$http.post(`/spl/sample/modifySampleInfo`, modifySampleFormData)
        if (res.code == 200 && res.data == 1) {
          this.$message.success('修改成功')
          this.getSourceList()
        } else {
          this.$message.error(res.msg)
        }
        this.sampleInfo = false
        this.getProjectInfo()
      }
    },
    // 获取项目详情列表
    async getProjectInfo() {
      const { data: res } = await this.$http.get(
        `/spl/project/getProjectInfo?id=${this.queryParams.id}&pageSize=${this.queryParams.pageSize}&pageNum=${this.queryParams.pageNum}&liverCancerResultArr=${this.queryParams.liverCancerResultArr}&lungCancerResultArr=${this.queryParams.lungCancerResultArr}&isConfirmArr=${this.queryParams.isConfirmArr}&productIdArr=${this.queryParams.productIdArr}&keyword=${this.queryParams.keyword}&sourceIdArr=${this.queryParams.sourceIdArr}`
      )
      if (res.code == 200) {
        this.tableColumnOptions = []
        if (this.jurisdiction != 1) {
          res.data.sampleListColumnResList.push({ label: '操作', name: 'operation', columnType: true, soltName: 'operation', width: 150 })
        }

        res.data.sampleListColumnResList.forEach((item) => {
          if (item.name == 'customerNumber') {
            item.lineFeed = true
            item.columnType = true
            item.soltName = 'link'
            item.width = '130'
          } else if (item.name == 'remarks') {
            item.lineFeed = true
            item.columnType = true
            item.soltName = 'remarks'
          } else if (item.name == 'lungCancerResult') {
            item.columnType = true
            item.soltName = 'lungCancerResult'
            item.width = '60'
            item.filters = [
              { text: '阳性', value: '1' },
              { text: '阴性', value: '0' }
            ]
            item.columnKey = 'lung'
          } else if (item.name == 'liverCancerResult') {
            item.width = '60'
            item.columnType = true
            item.soltName = 'liverCancerResult'
            item.filters = [
              { text: '阳性', value: '1' },
              { text: '阴性', value: '0' }
            ]
            item.columnKey = 'liver'
          } else if (item.name == 'isConfirm') {
            item.columnType = true
            item.soltName = 'isConfirm'
            item.width = '80'
            item.filters = [
              { text: '已交付', value: 'true' },
              { text: '未交付', value: 'false' }
            ]
            item.columnKey = 'isConfirm'
          } else if (item.name == 'productName') {
            item.columnType = true
            item.soltName = 'productName'
            item.filters = this.fileList
            item.columnKey = 'productName'
          } else if (item.name == 'source') {
            item.columnType = true
            item.soltName = 'source'
            item.filters = this.fileSourceList
            item.columnKey = 'source'
          } else if (item.name == 'dataSize') {
            item.width = '70'
            item.columnType = true
            item.soltName = 'dataSize'
            item.columnKey = 'dataSize'
          } else if (item.name == 'batch') {
            item.width = '50'
            item.columnType = true
            item.soltName = 'batch'
            item.columnKey = 'batch'
          } else if (item.name == 'customerName') {
            item.width = '100'
            item.columnType = true
            item.soltName = 'customerName'
            item.columnKey = 'customerName'
          }
          this.tableColumnOptions.push(item)
        })

        this.projectDetailsList = res.data
        this.total = res.data.sampleListReqList.total
        this.tableData = res.data.sampleListReqList.list
        this.modifyProjectInfolist.name = this.projectDetailsList.name
        this.modifyProjectInfolist.commander = this.projectDetailsList.commander
        this.modifyProjectInfolist.endTime = this.projectDetailsList.endTime
        this.modifyProjectInfolist.introduction = this.projectDetailsList.introduction
        this.tableData.forEach((item, index) => {
          if (item.experimentProcessList != null) {
            item.experimentProcessList.forEach((item1, index) => {
              if (index == 0) {
                item.experimentProcessList[index] = item1
              } else {
                item.experimentProcessList[index] = '|' + ' ' + item1
              }
            })
          }
          if (item.rateList != null) {
            item.rateList.forEach((item2, index) => {
              if (index == item.rateList.length - 1) {
                item.rateList[index] = item2
              } else {
                item.rateList[index] = item2 + ' ' + '|' + ' '
              }
            })
          }
        })
      } else if (res.code == 70001) {
        this.back()
      }
      this.loading = false
    },
    // 确认交付
    async sampleAccomplish(_id) {
      const msg = await this.$confirm('你确定该样本已邮寄?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
      if (msg === 'confirm') {
        const { data: res } = await this.$http.get(`/spl/sample/confirmSample?id=${_id}`)
        if (res.code == 200) {
          this.$message.success('确认成功')
        } else {
          this.$message.warning('确认失败')
        }
        this.getProjectInfo()
      }
    },
    // 批量跳转样本
    handle() {
      if (this.multipleSelection == '') {
        this.$message.error('请至少选择一个再点击！')
      } else {
        this.multipleSelection.forEach((item, index) => {
          if (index == 0) {
            this.sampleIdArray = item
          } else {
            this.sampleIdArray = this.sampleIdArray + ',' + item
          }
        })
        this.$router.push({ path: '/sampleInfo', query: { id: this.sampleIdArray, projectId: this.projectId } })
      }
      this.sampleIdArray = ''
    },
    // 点击勾选框选中的数据
    selectionChange(e) {
      var selectiList = []
      e.forEach((element) => {
        selectiList.push(element.id)
      })
      this.multipleSelection = selectiList
      if (selectiList.length > 0) {
        this.DerivedSample = true
      } else {
        this.DerivedSample = false
      }
    },
    filterChange(filterObj) {
      if (filterObj.liver) {
        this.queryParams.liverCancerResultArr = filterObj.liver
      } else if (filterObj.lung) {
        this.queryParams.lungCancerResultArr = filterObj.lung
      } else if (filterObj.isConfirm) {
        this.queryParams.isConfirmArr = filterObj.isConfirm
      } else if (filterObj.productName) {
        this.queryParams.productIdArr = filterObj.productName
      } else if (filterObj.source) {
        this.queryParams.sourceIdArr = filterObj.source
      }
      this.queryParams.pageNum = 1
      this.getProjectInfo()
    },
    // 状态改变时触发
    fileChange(uploadFile) {
      this.uploadingSample.file = uploadFile.raw
    },
    sampleFileChange(uploadFile) {
      this.uploadingSample.file = uploadFile.raw
    }
  }
}
</script>
<style lang='scss' scoped>
.projectContent {
  .project {
    margin-top: 20px;
    .projectTime {
      font-size: 30px;
    }
    .projectDetails {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-content: space-between;
      justify-content: space-between;
      .projectName {
        display: flex;
        margin-top: 10px;
      }
    }
    .projectBrief {
      display: flex;
      margin-top: 10px;

      .intro {
        min-width: 7%;
      }
      .intro-text {
        word-break: break-all;
        word-wrap: break-word;
      }
    }
  }
  .sampleList {
    margin-top: 20px;
  }
  .btn {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
  }
  .pagination {
    padding-top: 10px;
    text-align: right;
  }
  .obvious {
    color: red;
  }
  .affirm {
    color: #99cc99;
  }
  .link {
    color: #429ffe;
  }

  .remarks {
    color: #fd9933;
  }
  ::v-deep .el-table .el-table__cell {
    padding: 0 0;
  }
  ::v-deep .el-table__column-filter-trigger i {
    color: #fff;
  }
  ::v-deep .el-table th.el-table__cell > .cell {
    font-size: 12px;
  }
  ::v-deep .el-table .cell {
    font-size: 12px;
  }
}
</style>