<!-- 首页 -->
<template>
  <div id="app"
       @click="tagMenuShow=false">
    <el-container class="container">
      <!-- 左 -->
      <!-- 左侧图标 -->
      <el-aside :width="isCollapse?'65px':'170px'"
                class="aside">
        <div class="logo">
          <img src="../assets/images/log.png"
               alt="">
          <h1 v-show="!isCollapse">样本管理系统</h1>
        </div>
        <!-- 左侧菜单栏 -->
        <el-menu :default-active="currentPath"
                 class="el-menu-vertical-demo"
                 background-color="#304156"
                 text-color="#fff"
                 :collapse="isCollapse"
                 active-text-color="#ffd04b"
                 :unique-opened="true"
                 :collapse-transition="false"
                 router>
          <template v-for="item in menuList">
            <el-submenu :index="item.path"
                        :key="item.path"
                        v-if="item.children&&item.visiable">
              <!-- 一级菜单里面包含二级菜单 -->
              <template slot="title">
                <i :class="'iconfont icon-'+item.meta.icon"></i>
                <span>{{item.meta.title}}</span>
              </template>
              <!-- 二级菜单 -->
              <template v-for="menu in item.children">

                <el-menu-item class="second"
                              :index="menu.path"
                              :key="menu.path"
                              v-if="!menu.hidden&&menu.visiable">
                  <i :class="'iconfont icon-'+menu.meta.icon"></i>
                  <span>{{menu.meta.title}}</span>
                </el-menu-item>
              </template>
            </el-submenu>
            <!-- 一级菜单 -->
            <el-menu-item :index="item.path"
                          :key="item.path"
                          v-show="item.visiable"
                          v-if="!item.children&&item.visiable">
              <i :class="'iconfont icon-'+item.meta.icon"></i>
              <span slot="title">{{item.meta.title}}</span>
            </el-menu-item>
          </template>
        </el-menu>
      </el-aside>
      <!-- 右 -->
      <el-main>
        <!-- 顶部导航 -->
        <el-row type="flex"
                justify="space-between"
                align="middle">
          <!-- 折叠 -->
          <el-col :span="1"
                  class="hidn-show">
            <el-radio-group v-model="isCollapse">
              <i v-show="!isCollapse"
                 class="iconfont icon-shousuo"
                 @click="isCollapse=true"></i>
              <i v-show="isCollapse"
                 class="iconfont icon-zhankai"
                 @click="isCollapse=false"></i>
            </el-radio-group>
          </el-col>
          <!-- 导航栏 -->
          <el-col :span="20">
            <el-tabs v-model="activeName">
              <!-- @tab-click="handleClick" -->
              <el-tab-pane v-for="(item) in routerList"
                           :key="item.path">
                <span slot="label"><i :class="'iconfont icon-'+item.meta.icon"></i>{{item.meta.title}}</span>
              </el-tab-pane>
            </el-tabs>
          </el-col>
          <el-col :span="0.5">
            <span v-show=full
                  class="iconfont icon-quanping"
                  @click="fullScreen()"></span>
            <span v-show=partially
                  class="iconfont icon-24gl-shrink"
                  @click="restore()"></span>
          </el-col>

          <!-- 头像 -->
          <el-col :span="2">
            <el-dropdown trigger="click">
              <span class="el-dropdown-link">
                {{this.txname}}
                <i class="el-icon-caret-bottom el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item class="clearfix"
                                  @click.native="tc">
                  退出登录
                  <el-badge class="mark" />
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </el-col>
        </el-row>
        <!-- 顶部标签页 -->
        <el-divider></el-divider>
        <!-- 展示区域 -->
        <div class="zs">
          <transition name="fade"
                      mode="out-in">
            <router-view :key="time"></router-view>
          </transition>
        </div>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import info from '../views/management-center/info/info.vue'
import sample from '../views/management-center/sample/sample.vue'
import sampleInfo from '../views/management-center/sample/sampleInfo.vue'

// 项目管理
import project from '../views/management-center/project/project.vue'
import projectPrticulars from '../views/management-center/project/projectPrticulars.vue'
import userManagement from '../views/management-center/userManagement/userManagement.vue'
import processTemplates from '../views/management-center/templateManagement/processTemplates.vue'
import stepTemplate from '../views/management-center/templateManagement/stepTemplate.vue'
import setPermission from '../views/management-center/setPermission/setPermission.vue'
import log from '../views/management-center/log/log.vue'
import source from '../views/management-center/source/source.vue'
export default {
  data() {
    return {
      //管理员信息
      adminxx: {
        type: '1'
      },
      full: true,
      partially: false,
      isCollapse: true,
      //用户名字
      txname: '',
      //用户头像
      imglist: '',
      // 个人信息
      userInfo: [],
      // 全部数据
      routerList: [],
      // 左侧菜单栏
      menuList: [
        {
          name: '/project',
          path: '/project',
          hidden: false,
          authType: [],
          component: 'project',
          visiable: true,
          meta: {
            title: '项目管理',
            icon: 'xiangmu',
            noCache: false
          }
        },
        {
          name: '/sample',
          path: '/sample',
          hidden: false,
          authType: [],
          component: 'sample',
          visiable: true,
          meta: {
            title: '样本管理',
            icon: 'yangbenxinxidan',
            noCache: false
          }
        },
        {
          name: '/templateManagement',
          path: '/templateManagement',
          hidden: false,
          component: 'templateManagement',
          visiable: false,
          authType: ['1'],
          meta: {
            title: '模板管理',
            icon: 'moban',
            noCache: false
          },
          children: [
            {
              name: '/processTemplates',
              path: '/processTemplates',
              hidden: false,
              component: 'processTemplates',
              authType: ['1'],
              meta: {
                title: '流程模板管理',
                icon: 'shenpiliuchengmoban',
                noCache: false
              },
              visiable: true
            },
            {
              name: '/stepTemplate',
              path: '/stepTemplate',
              hidden: false,
              component: 'stepTemplate',
              authType: ['1'],
              meta: {
                title: '步骤模板管理',
                icon: 'gongyibuzhoumoban',
                noCache: false
              },

              visiable: true
            }
          ]
        },
        {
          name: '/userManagement',
          path: '/userManagement',
          hidden: false,
          component: 'userManagement',
          visiable: false,
          authType: ['1'],
          meta: {
            title: '用户管理',
            icon: 'yonghu',
            noCache: false
          }
        },
        {
          name: '/product',
          path: '/product',
          hidden: false,
          component: 'product',
          visiable: false,
          authType: ['1'],
          meta: {
            title: '产品管理',
            icon: 'chanpin',
            noCache: false
          }
        },
        {
          name: '/source',
          path: '/source',
          hidden: false,
          component: 'source',
          visiable: false,
          authType: ['1'],
          meta: {
            title: '来源管理',
            icon: 'laiyuanfenxi',
            noCache: false
          }
        },
        {
          name: '/sampleInfo',
          path: '/sampleInfo',
          hidden: true,
          component: 'sampleInfo',
          authType: [],
          visiable: false,
          meta: {
            title: '样本详情',
            icon: 'build',
            noCache: false
          }
        },
        {
          name: '/setPermission',
          path: '/setPermission',
          hidden: true,
          component: 'setPermission',
          authType: [],
          visiable: false,
          meta: {
            title: '分配权限',
            icon: 'build',
            noCache: false
          }
        },
        {
          name: '/project/projectPrticulars',
          path: '/project/projectPrticulars',
          hidden: true,
          component: 'projectPrticulars',
          authType: [],
          visiable: false,
          meta: {
            title: '项目详情',
            icon: 'build',
            noCache: false
          }
        },
        {
          name: '/log',
          path: '/log',
          hidden: false,
          component: info,
          authType: ['1'],
          visiable: true,
          meta: {
            title: '操作日志',
            icon: 'caozuorizhi',
            noCache: false
          }
        },
        {
          name: '/info',
          path: '/info',
          hidden: false,
          component: info,
          authType: [],
          visiable: true,
          meta: {
            title: '个人信息',
            icon: 'gerenxinxi',
            noCache: false
          }
        }
      ],
      // 当前菜单全部数据的下标
      menuActiveIndex: 0,
      activeName: 'second',
      tagList: [{ title: '首页', path: '/index', status: true }],
      currentPath: '/',
      tagMenuShow: false,
      x: 0,
      y: 0,
      time: Date.now()
    }
  },
  mounted() {
    //如果currentTagList有缓存，则初始化tagList
    const currentTagList = sessionStorage.getItem('currentTagList')
    if (currentTagList) this.tagList = JSON.parse(currentTagList)
    //初始化当前的路径currentPath
    this.currentPath = this.$route.path
    this.tx()
    this.setPermission()
    // this.setRoleShow()
    // this.parseAuth()
  },

  watch: {
    // 监听路由的改变
    $route(route) {
      //更新currentPath当前路径
      this.currentPath = route.path
      //先把所有状态改变false
      this.tagList.forEach((el) => (el.status = false))
      //当前路由
      const { path, meta } = route
      //判断当前路由路径是否存在tagList数组中
      let tag = this.tagList.find((el) => el.path == path)
      if (tag) {
        tag.status = true
      } else {
        //如果不存在则添加到tagList数组中
        tag = { title: meta.title, path: path, status: true }
        this.tagList.push(tag)
      }
      //把当前的路径存放到本地中
      if (tag.path === '/') {
        sessionStorage.setItem('currentTagList', JSON.stringify([tag]))
      }
      if (tag.path !== '/') {
        sessionStorage.setItem('currentTagList', JSON.stringify([{ title: '首页', path: '/index', status: true }, tag]))
      }
    }
  },
  methods: {
    setPermission() {
      var identity = window.sessionStorage.getItem('identity')
      this.menuList.forEach((element) => {
        if (element.authType.includes(identity)) {
          element.visiable = true
        }
        if (element.children != undefined && element.children.length > 0) {
          element.children.forEach((item) => {
            if (item.authType.includes(identity)) {
              item.visiable = true
            } else {
              item.visiable = false
            }
          })
        }
      })
    },

    tc() {
      // window.sessionStorage.removeItem('role')
      window.sessionStorage.removeItem('token')
      window.sessionStorage.removeItem('currentTagList')
      // for (var i = 1; i <= 11; i++) {
      //   window.sessionStorage.removeItem('auth-' + i)
      // }
      // window.sessionStorage.removeItem('auth')
      window.sessionStorage.removeItem('tokenStartTime')
      this.$router.push('/')
    },
    //头像信息
    async tx() {
      const { data: res } = await this.$http.get('/spl/user/getUserInfo')
      this.txname = res.data.username
      this.imglist = res.data.avatar
    },

    // 关闭tag处理
    closeHandler(tag, index) {
      //判断关闭是否是当前选中的
      if (tag.status) {
        let t = null
        //判断当前选中的是否为最后一个tag
        if (index == this.tagList.length - 1) {
          t = this.tagList[index - 1] //获取当一个tag对象
        } else {
          t = this.tagList[this.tagList.length - 1] //获取最后一个tag对象
        }
        //改变状态
        t.status = true
        this.$router.push(t.path)
      }
      this.tagList = this.tagList.filter((el, i) => i != index)
    },
    // 打开全屏
    fullScreen() {
      document.documentElement.requestFullscreen()
      this.full = false
      this.partially = true
    },
    // 关闭全屏
    restore() {
      document.exitFullscreen()
      this.full = true
      this.partially = false
    }
  }
}
</script>
<style lang='scss' scoped>
// 设置切换动画
.fade-level-active,
.fade-enter-active {
  transition: all 0.5s;
}
.avatar {
  width: 30px;
  height: 30px;
}
.fade-enter {
  opacity: 0;
  transform: translateX(-30px);
}
.fade-level-to {
  opacity: 0;
  transform: translateX(30px);
}

.container {
  width: 100vw;
  height: 100vh;
}
.aside {
  background: #304156;
}

// 左边logo
.logo {
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  img {
    width: 22px;
    margin-right: 10px;
  }
}
.menu-left {
  border-right: none;
  .second {
    background: #1f2d3d !important;
    min-width: 0;
  }
  .second:hover {
    background: #001528 !important;
  }
}
// 右边折叠
.hidn-show {
  span {
    width: 100%;
    font-size: 20px;
  }
}
// 头像
.el-dropdown-link {
  margin-left: 25px;
  font-size: 17px;
}
// 顶部可关闭标签页
.tagbar {
  margin-top: 12px;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 /12%), 0 0 3px 0 rgb(0 0 0 /4%);

  .list {
    padding-left: 14px;
    height: 34px;
    line-height: 34px;
    .item {
      margin-right: 5px;
    }
    .active {
      background: #1890ff;
      color: #fff;

      &::before {
        content: '';
        display: inline-block;
        width: 8px;
        height: 8px;
        background: #fff;
        border-radius: 50%;
      }
    }
  }
}

//tag操作列表
.contextmenu {
  position: absolute;
  background: #fff;
  width: 88px;
  box-shadow: 2px 2px 3px 0 rgb(0 0 0 / 30%);
  font-size: 12px;
  color: #999;
  // text-align: center;
  z-index: 999;
  li {
    padding: 10px;

    &:hover {
      background: #eeeeee;
    }
  }
}
.iconfont {
  margin-right: 5px;
  font-size: 21px;
}
::v-deep.el-submenu .el-menu-item {
  min-width: 0px;
}
</style>