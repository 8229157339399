<!--流程模板管理 -->
<template>
  <div>
    <el-button type="success"
               @click="openProductDialogVisible"><i class="el-icon-plus"></i>添加产品</el-button>
    <el-card class="box-card">
      <el-table :data="productList"
                align="center"
                style="width: 100%"
                :header-cell-style="{background: '#4f71be',color: '#fff'}">
        <el-table-column label="产品名称"
                         align="center"
                         prop="name">
        </el-table-column>
        <el-table-column label="序列"
                         align="center"
                         prop="index">
        </el-table-column>
        <el-table-column label="状态"
                         align="center"
                         prop="status"
                         width="150">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.status"
                       active-color="#13ce66"
                       inactive-color="#ff4949"
                       :active-value="0"
                       :inactive-value="1"
                       active-text="启用"
                       inactive-text="禁用"
                       @change="modifyTemplateProcessStatus(scope.row)"></el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作"
                         align="center">
          <template slot-scope="scope">
            <el-button type="text"
                       icon="el-icon-edit"
                       @click="openmodifyTemplateProcess(scope.row)"
                       style="color:#409eff;">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>

    <!-- 修改产品信息 -->
    <template>
      <el-dialog title="修改产品信息"
                 :visible.sync="modifyTemplateProcessShow"
                 width="50%">
        <div class="demo-input-suffix">
          <el-form :model="modifyProductList"
                   ref="modifySampleInfoList"
                   label-width="135px">
            <el-form-item label="排序"
                          prop="index">
              <el-input v-model="modifyProductList.index"></el-input>
            </el-form-item>
            <el-form-item label="产品名称"
                          prop="name">
              <el-input v-model="modifyProductList.name"></el-input>
            </el-form-item>

          </el-form>
        </div>
        <span slot="footer"
              class="dialog-footer">
          <el-button @click="modifyTemplateProcessShow = false">取 消</el-button>
          <el-button type="success"
                     @click="modifyProduct()">提交</el-button>
        </span>
      </el-dialog>
    </template>

    <!-- 添加产品对话框 -->
    <template>
      <el-dialog title="添加产品"
                 :visible.sync="addProductDialogVisible"
                 width="50%">

        <el-form ref="form"
                 :model="form"
                 label-width="80px">
          <el-form-item label="产品名称">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item label="产品序列">
            <el-input v-model="form.index"></el-input>
          </el-form-item>
          <el-form-item label="产品状态">
            <el-switch v-model="form.status"
                       active-color="#13ce66"
                       inactive-color="#ff4949"
                       :active-value="0"
                       :inactive-value="1"
                       active-text="启用"
                       inactive-text="禁用"></el-switch>
          </el-form-item>

          <el-form-item>
            <el-button type="primary"
                       @click="onSubmit()">提交</el-button>
            <el-button @click="addProductDialogVisible=false">取消</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
    </template>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        name: '',
        status: 0,
        index: ''
      },
      productList: [],
      // 修改流程对话框
      modifyTemplateProcessShow: false,
      //添加产品对话框
      addProductDialogVisible: false,
      getProductList: {
        pageSize: 50,
        pageNum: 1
      },
      modifyProductList: {
        id: '',
        name: '',
        status: '',
        index: ''
      }
    }
  },
  mounted() {
    this.getAllProductList()
  },

  methods: {
    async onSubmit() {
      const { data: res } = await this.$http.post('/spl/product/addProduct', this.form)
      if (res.code == 200) {
        this.$message.success('添加成功')
      } else {
        this.$message.error(rss.msg)
      }
      this.addProductDialogVisible = false
      this.getAllProductList()
    },
    // 修改产品信息
    async modifyProduct() {
      const { data: res } = await this.$http.post('/spl/product/modifyProduct', this.modifyProductList)
      if (res.code == 200) {
        this.$message.success('修改成功')
        this.modifyTemplateProcessShow = false
        this.getAllProductList()
      } else {
        this.$message.error(res.msg)
      }
    },
    // 获取产品列表
    async getAllProductList() {
      const { data: res } = await this.$http.get(`/spl/product/getAllProductList?pageSize=${this.getProductList.pageSize}&pageNum=${this.getProductList.pageNum}`)
      this.productList = res.data.list
    },
    // 打开产品对话框
    async openProductDialogVisible() {
      this.addProductDialogVisible = true
    },
    async modifyTemplateProcessStatus(row) {
      this.modifyProductList.status = row.status
      this.modifyProductList.id = row.id
      this.modifyProductList.name = row.name
      this.modifyProductList.index = row.index
      this.modifyProduct()
    },
    // 打开修改流程对话框
    async openmodifyTemplateProcess(row) {
      this.modifyProductList.id = row.id
      this.modifyProductList.name = row.name
      this.modifyProductList.index = row.index
      this.modifyTemplateProcessShow = true
    }
  }
}
</script>
<style lang='scss' scoped>
.demo-table-expand {
  font-size: 0;
}
.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}
.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 100%;
  display: flex;
}
.el-tag {
  margin: 5px;
}
::v-deep.el-form--inline .el-form-item__label {
  width: 110px;
}
::v-deep.el-table td.el-table__cell div {
  width: 100%;
}
::v-deep .el-input {
  width: 90%;
}
</style>