<!--流程模板管理 -->
<template>
  <div>
    <el-card class="box-card">
      <el-button type="success"
                 @click="addFlowTemplate=true">添加流程模板</el-button>
      <el-table :data="templateProcessList"
                align="center"
                style="width: 100%"
                :header-cell-style="{background: '#4f71be',color: '#fff'}">
        <el-table-column type="expand">
          <template slot-scope="props">
            <el-form label-position="left"
                     inline
                     class="demo-table-expand">
              <el-form-item v-for="item in  props.row.templateStageModelList"
                            :key="item.id"
                            :label="item.name">
                <el-tag v-for="item1 in item.attributeModelList"
                        :key="item1.index"
                        style="margin-left10px">{{item1.name}}</el-tag>
              </el-form-item>
            </el-form>
          </template>
        </el-table-column>
        <el-table-column label="流程编号"
                         align="center"
                         prop="id">
        </el-table-column>
        <el-table-column label="流程名称"
                         align="center"
                         prop="name">
        </el-table-column>
        <el-table-column width="650"
                         align="center"
                         label="步骤"
                         prop="templateStageModelList">

          <template slot-scope="scope">
            <el-tag v-for="item in scope.row.templateStageModelList"
                    :key="item.id"
                    type="info"
                    disable-transitions> {{item.name}}</el-tag>
          </template>

        </el-table-column>
        <el-table-column label="状态"
                         align="center"
                         prop="status"
                         width="150">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.status"
                       active-color="#13ce66"
                       inactive-color="#ff4949"
                       :active-value="0"
                       :inactive-value="1"
                       active-text="启用"
                       inactive-text="禁用"
                       @change="modifyTemplateProcessStatus(scope.row)"></el-switch>
          </template>
        </el-table-column>
        <el-table-column label="显示项目类型"
                         align="center"
                         prop="projectTypeArr"
                         :formatter="typeformatter">

        </el-table-column>
        <el-table-column label="操作"
                         align="center">
          <template slot-scope="scope">
            <el-button type="text"
                       icon="el-icon-edit"
                       @click="openmodifyTemplateProcess(scope.row)"
                       style="color:#409eff;">编辑步骤</el-button>

            <el-button type="text"
                       icon="el-icon-edit"
                       @click="openmodifyInfo(scope.row)"
                       style="color:#409eff;">修改信息</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>

    <!-- 修改流程模板 -->
    <template>
      <el-dialog title="修改流程模板"
                 :visible.sync="modifyTemplateProcessShow"
                 width="50%">
        <div>
          <template>
            <el-transfer target-order=push
                         filterable
                         @change="handleChange"
                         :titles="['未选模板步骤', '已选模板步骤']"
                         :filter-method="filterMethod"
                         filter-placeholder="请输入模板步骤名称"
                         v-model="value"
                         :data="data">
            </el-transfer>
          </template>
        </div>
        <span slot="footer"
              class="dialog-footer">
          <el-button @click="modifyTemplateProcessShow = false">取 消</el-button>
          <el-button type="success"
                     @click="modifyTemplateProcess">提交</el-button>
        </span>
      </el-dialog>
    </template>

    <!-- 添加流程模板 -->
    <template>
      <el-dialog title="添加流程模板"
                 :visible.sync="addFlowTemplate"
                 width="50%">
        <el-form ref="form"
                 :model="form"
                 :rules="rules"
                 label-width="80px">
          <el-form-item label="流程名称"
                        prop="name">
            <el-input v-model="form.name"
                      placeholder="请输入流程名称"></el-input>
          </el-form-item>
          <el-form-item label="项目类型"
                        prop="projectTypeArr">
            <el-select v-model="form.projectTypeArr"
                       multiple
                       placeholder="请选择项目类型">
              <el-option label="LDT"
                         value="1"></el-option>
              <el-option label="CRO"
                         value="2"></el-option>
              <el-option label="RES"
                         value="3"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <span slot="footer"
              class="dialog-footer">
          <el-button @click="addFlowTemplate = false">取 消</el-button>
          <el-button type="success"
                     @click="submitFlow('form')">提交</el-button>
        </span>
      </el-dialog>
    </template>

    <!-- 修改信息模板 -->
    <template>
      <el-dialog title="修改信息"
                 :visible.sync="changeInfo"
                 width="50%">
        <el-form ref="infoList"
                 :model="infoList"
                 :rules="rules1"
                 label-width="80px">
          <el-form-item label="流程名称"
                        prop="name">
            <el-input v-model="infoList.name"
                      placeholder="请输入流程名称"></el-input>
          </el-form-item>
          <el-form-item label="项目类型"
                        prop="projectTypeArr">
            <el-select v-model="infoList.projectTypeArr"
                       multiple
                       placeholder="请选择项目类型">
              <el-option label="LDT"
                         value="1"></el-option>
              <el-option label="CRO"
                         value="2"></el-option>
              <el-option label="RES"
                         value="3"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <span slot="footer"
              class="dialog-footer">
          <el-button @click="changeInfo = false">取 消</el-button>
          <el-button type="success"
                     @click="submitInfo('infoList')">提交</el-button>
        </span>
      </el-dialog>
    </template>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: [],
      value: [],
      templateProcessList: [],
      form: { name: '', projectTypeArr: [] },
      // 修改流程对话框
      modifyTemplateProcessShow: false,
      // 控制添加流程模板
      addFlowTemplate: false,
      // 修改信息
      changeInfo: false,
      // 穿梭框搜索
      filterMethod(query, item) {
        return item.label.indexOf(query) > -1
      },
      // 修改流程模板入参
      stageUpdateObj: {
        templateProcessId: '',
        templateStageIdArray: []
      },
      changeAttributeList: [],
      allAttributeList: [],
      infoList: { name: '', projectTypeArr: [], templateProcessId: '' },
      rules: {
        name: [{ required: true, message: '请输入流程名称', trigger: 'blur' }],
        projectTypeArr: [{ required: true, message: '请选择项目类型', trigger: 'change' }]
      },
      rules1: {
        name: [{ required: true, message: '请输入流程名称', trigger: 'blur' }],
        projectTypeArr: [{ required: true, message: '请选择项目类型', trigger: 'change' }]
      }
    }
  },
  mounted() {
    this.getTemplateProcessList()
  },

  methods: {
    async getTemplateProcessList() {
      const { data: res } = await this.$http.get('/spl/manage/template/getTemplateProcessList')
      this.templateProcessList = res.data
    },
    async modifyTemplateProcessStatus(row) {
      const { data: res } = await this.$http.get(`/spl/manage/template/modifyTemplateProcessStatus?id=${row.id}&status=${row.status}`)
      if (res.code == 200 && res.data == 1) {
        this.$message.success('修改成功')
      } else {
        this.$message.error(rss.msg)
      }
    },
    // 打开修改流程对话框
    async openmodifyTemplateProcess(row) {
      this.stageUpdateObj.templateProcessId = row.id
      this.modifyTemplateProcessShow = true
      this.data = []
      this.value = []
      var selectedList = []
      row.templateStageModelList.forEach((att) => {
        selectedList.push(att.id)
      })
      var resultList = selectedList
      const { data: res } = await this.$http.get(`/spl/manage/template/getTemplateStageList`)
      res.data.forEach((att, index) => {
        if (selectedList.indexOf(att.id) != -1) {
          var ind = selectedList.indexOf(att.id)
          resultList[ind] = index
        }
        this.allAttributeList.push(att.id)
        this.data.push({
          label: att.name,
          key: index,
          id: att.id
        })
      })
      this.value = resultList
    },
    // 修改流程提交
    async modifyTemplateProcess() {
      if (this.changeAttributeList <= 0) {
        return this.$message.error('已选流程不能为空')
      }
      this.stageUpdateObj.templateStageIdArray = this.changeAttributeList
      const { data: res } = await this.$http.post('/spl/manage/template/modifyTemplateProcess', this.stageUpdateObj)
      if (res.code == 200 && res.data == 1) {
        this.modifyTemplateProcessShow = false
        this.$message.success('修改流程成功')
        this.getTemplateProcessList()
      } else {
        this.$message.error(res.msg)
      }
    },
    handleChange(value) {
      this.changeAttributeList = []
      value.forEach((item) => {
        this.changeAttributeList.push(this.allAttributeList[item])
      })
    },
    submitFlow(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const { data: res } = await this.$http.post('/spl/manage/template/addTemplateProcess', this.form)
          if (res.code == 200) {
            this.$message.success('添加流程成功')
            this.addFlowTemplate = false
            this.form = {}
            this.getTemplateProcessList()
          } else {
            this.$message.error(res.msg)
          }
        }
      })
    },
    typeformatter(row) {
      return row.projectTypeArr.join('|').replace(1, 'LDT').replace(2, 'CRO').replace(3, 'RES')
    },
    openmodifyInfo(row) {
      this.infoList.name = row.name
      this.infoList.projectTypeArr = row.projectTypeArr
      this.infoList.templateProcessId = row.id
      this.changeInfo = true
    },
    submitInfo(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const { data: res } = await this.$http.post('/spl/manage/template/modifyTemplateInfo', this.infoList)
          if (res.code == 200) {
            this.$message.success('修改信息成功')
            this.changeInfo = false
            this.getTemplateProcessList()
          } else {
            this.$message.error(res.msg)
          }
        }
      })
    }
  }
}
</script>
<style lang='scss' scoped>
.demo-table-expand {
  font-size: 0;
}
.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}
.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 100%;
  display: flex;
}
.el-tag {
  margin: 5px;
}
::v-deep.el-form--inline .el-form-item__label {
  width: 110px;
}
::v-deep.el-table td.el-table__cell div {
  width: 100%;
}
::v-deep .el-input {
  width: 90%;
}
</style>