<!-- 分配权限页面 -->
<template>
  <div>
    <el-form :model="queryParams"
             ref="queryParams"
             size="small"
             :inline="true"
             label-width="78px">
      <el-form-item>
        <el-button size="medium"
                   icon="el-icon-arrow-left"
                   @click="back">返回项目详情</el-button>
        <el-button type="success"
                   size="medium"
                   icon="el-icon-plus"
                   @click="addPermission">添加权限</el-button>
      </el-form-item>
    </el-form>

    <!-- 添加用户对话框 -->
    <template>
      <el-dialog title="添加用户权限"
                 :visible.sync="addDialogVisible"
                 width="50%">
        <div class="demo-input-suffix">
          <el-checkbox :indeterminate="isIndeterminate"
                       v-model="checkAll"
                       @change="handleCheckAllChange">全选</el-checkbox>
          <div style="margin: 15px 0;"></div>
          <el-checkbox-group v-model="checkedCities">
            <el-checkbox v-for="user in users"
                         :label="user.id"
                         :key="user.id">{{user.username}}</el-checkbox>
          </el-checkbox-group>
        </div>
        <span slot="footer"
              class="dialog-footer">
          <el-button @click="addDialogVisible = false">取 消</el-button>
          <el-button type="success"
                     @click="addProjectAuth()">提交</el-button>
        </span>
      </el-dialog>
    </template>

    <el-card class="box-card">
      <!-- 数据表格 -->
      <template>
        <el-table :data="userList"
                  align="center"
                  style="width: 100%"
                  :header-cell-style="{background: '#4f71be',color: '#fff'}">

          <el-table-column prop="username"
                           align="center"
                           label="用户名"></el-table-column>
          <el-table-column prop="type"
                           align="center"
                           label="权限类型"
                           width="150">
            <template slot-scope="scope">
              <el-switch v-model="scope.row.type"
                         active-color="#13ce66"
                         inactive-color="blue"
                         :active-value="2"
                         :inactive-value="1"
                         active-text="写"
                         inactive-text="读"
                         @change="modifyProjectAuth(scope.row)"></el-switch>
            </template>
          </el-table-column>
          <el-table-column label="操作"
                           align="center">
            <template slot-scope="scope">
              <el-button type="danger"
                         size="mini"
                         icon="el-icon-delete"
                         @click="delProjectAuth(scope.row.id)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </template>

      <!-- 分页导航 -->
      <template>
        <el-row class="pagination">
          <pagination v-show="total>0"
                      :total="total"
                      :page.sync="queryParams.pageNum"
                      :limit.sync="queryParams.pageSize"
                      @pagination="getProjectAuthList" />
        </el-row>
      </template>
    </el-card>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
export default {
  components: {
    Pagination
  },
  data() {
    return {
      checkAll: false,
      checkedCities: [],
      users: [],
      isIndeterminate: true,
      queryParams: {
        pageSize: 10,
        pageNum: 1,
        projectId: ''
      },
      total: 0,
      userList: [],
      addDialogVisible: false,
      recompose: {
        username: '',
        password: '',
        phone: '',
        email: ''
      },
      projectAuthObj: {
        projectId: ''
      },
      // 添加权限数组id
      checkedUser: [],
      rules: {
        username: [{ required: true, message: '请输入用户名', trigger: 'change' }],
        password: [{ required: true, message: '请输入密码', trigger: 'change' }],
        email: [{ type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }],
        phone: [{ min: 11, max: 11, message: '请输入正确的手机号', trigger: 'blur' }]
      }
    }
  },
  mounted() {
    this.queryParams.projectId = this.$route.query.projectId
    this.projectAuthObj.projectId = this.$route.query.projectId
    this.getProjectAuthList()
  },

  methods: {
    // 控制全选
    handleCheckAllChange(val) {
      this.checkedCities = []
      if (val) {
        this.users.forEach((index) => {
          this.checkedCities.push(index.id)
        })
        // this.projectAuthObj.userIdArray = this.checkedCities
      }
      this.isIndeterminate = false
    },
    async addPermission() {
      this.addDialogVisible = true
      const { data: res } = await this.$http.get(`/spl/projectAuth/getUserInfoList?projectId=${this.queryParams.projectId}`)
      this.users = res.data
    },
    // 获取用户列表
    async getProjectAuthList() {
      const { data: res } = await this.$http.get('/spl/projectAuth/getProjectAuthList', { params: this.queryParams })
      this.userList = res.data.list
      this.total = res.data.total
    },
    async addProjectAuth() {
      console.log(this.checkedCities.length)
      if (this.checkedCities.length < 1) {
        this.$message.error('请至少选择一个')
      } else {
        this.projectAuthObj.userIdArray = this.checkedCities
        const { data: res } = await this.$http.post('/spl/projectAuth/addProjectAuth', this.projectAuthObj)
        if (res.code == 200) {
          this.checkedCities = []
          this.addDialogVisible = false
          this.$message.success('添加成功')
        } else {
          this.this.checkedCities = []
          this.$message.error(res.msg)
        }
      }
      this.getProjectAuthList()
    },
    // 设置权限
    async modifyProjectAuth(row) {
      const { data: res } = await this.$http.post(`/spl/projectAuth/modifyProjectAuth`, { type: row.type, id: row.id })
      if (res.data == 1) {
        this.$message.success('设置权限成功')
      } else {
        this.$message.error(res.msg)
      }
    },
    // 删除权限
    async delProjectAuth(id) {
      const msg = await this.$confirm('你确定删除当前用户的权限吗！', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
      if (msg === 'confirm') {
        //做删除操作
        const { data: res } = await this.$http.get(`/spl/projectAuth/delProjectAuth?id=${id}`)
        if (res.code == 200) {
          this.getProjectAuthList()
          return this.$message.success('删除成功！')
        } else {
          return this.$message.warning('删除失败')
        }
      }
    },
    // 返回
    back() {
      this.$router.push({ path: '/project/projectPrticulars', query: { id: this.queryParams.projectId } })
    }
  }
}
</script>
<style lang='scss' scoped>
</style>