<!--来源管理 -->
<template>
  <div>
    <el-button type="success"
               @click="openSourceDialogVisible"><i class="el-icon-plus"></i>添加来源</el-button>
    <el-card class="box-card">
      <el-table :data="sourceList"
                align="center"
                style="width: 100%"
                :header-cell-style="{background: '#4f71be',color: '#fff'}">
        <el-table-column label="id"
                         align="center"
                         prop="id">
        </el-table-column>
        <el-table-column label="来源名称"
                         align="center"
                         prop="name">
        </el-table-column>
      </el-table>
    </el-card>

    <!-- 添加来源对话框 -->
    <template>
      <el-dialog title="添加来源"
                 :visible.sync="addSourceDialogVisible"
                 width="50%">

        <el-form ref="form"
                 :model="form"
                 label-width="80px">
          <el-form-item label="来源名称">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary"
                       @click="onSubmit()">提交</el-button>
            <el-button @click="addSourceDialogVisible=false">取消</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
    </template>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        name: ''
      },
      sourceList: [],
      //添加样本来源对话框
      addSourceDialogVisible: false,
      getProductList: {
        pageSize: 50,
        pageNum: 1
      }
    }
  },
  mounted() {
    this.getAllSourceList()
  },

  methods: {
    async onSubmit() {
      const { data: res } = await this.$http.post('/spl/source/addSource', this.form)
      if (res.code == 200) {
        this.$message.success('添加成功')
      } else {
        this.$message.error(rss.msg)
      }
      this.addSourceDialogVisible = false
      this.getAllSourceList()
    },

    // 获取全部来源列表
    async getAllSourceList() {
      const { data: res } = await this.$http.get(`/spl/source/getAllSourceList?pageSize=${this.getProductList.pageSize}&pageNum=${this.getProductList.pageNum}`)
      this.sourceList = res.data.list
    },
    // 打开添加来源对话框
    async openSourceDialogVisible() {
      this.addSourceDialogVisible = true
    }
  }
}
</script>
<style lang='scss' scoped>
.demo-table-expand {
  font-size: 0;
}
.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}
.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 100%;
  display: flex;
}
.el-tag {
  margin: 5px;
}
::v-deep.el-form--inline .el-form-item__label {
  width: 110px;
}
::v-deep.el-table td.el-table__cell div {
  width: 100%;
}
::v-deep .el-input {
  width: 90%;
}
</style>