<!-- 样本模块 -->
<template>
  <div>
    <!-- 头部搜索框 -->
    <el-form :model="queryParams"
             ref="queryParams"
             size="small"
             :inline="true"
             label-width="138px">
      <el-form-item label="样本编号/客户名称">
        <el-input siz="medium"
                  v-model="queryParams.keyword"
                  clearable
                  placeholder="请输入样本编号/客户名称"
                  @input="inquire()"></el-input>
      </el-form-item>
      <el-form-item label="创建时间">
        <el-date-picker v-model="value1"
                        type="daterange"
                        range-separator="————"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary"
                   size="medium"
                   icon="el-icon-search"
                   @click="inquire">搜索</el-button>
      </el-form-item>
    </el-form>

    <el-card class="box-card">
      <!-- 数据表格 -->
      <template>
        <el-table :data="tableData"
                  align="center"
                  style="width: 100%"
                  :header-cell-style="{background: '#4f71be',color: '#fff'}">
          <el-table-column prop="id"
                           align="center"
                           label="样本编号">
            <template slot-scope="scope">
              <span style="color:#429fff"
                    @click="updateHandler(scope.row.id,scope.row.projectId)">{{scope.row.id}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="projectName"
                           show-overflow-tooltip
                           align="center"
                           label="项目名称">
            <template slot-scope="scope">
              <span style="color:#429fff"
                    @click="toProject(scope.row.projectId)">{{scope.row.projectName}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="projectType"
                           align="center"
                           show-overflow-tooltip
                           width="100"
                           label="项目类型">
            <template slot-scope="scope">
              <span v-show="scope.row.projectType==1">LDT</span>
              <span v-show="scope.row.projectType==2">CRO</span>
              <span v-show="scope.row.projectType==3">RES</span>
            </template>
          </el-table-column>
          <el-table-column prop="type"
                           align="center"
                           label="样本类型">
            <template slot-scope="scope">
              <span>
                <span v-if="scope.row.type==1">动物组织</span>
                <span v-if="scope.row.type==2">植物组织</span>
                <span v-if="scope.row.type==3">血液</span>
                <span v-if="scope.row.type==4">细菌</span>
                <span v-if="scope.row.type==5">其他</span>
              </span>
            </template>
          </el-table-column>

          <el-table-column prop="customerName"
                           align="center"
                           label="客户名称"></el-table-column>
          <el-table-column prop="createTime"
                           align="center"
                           label="样本创建日期"></el-table-column>

          <el-table-column prop="experimentProcessList"
                           align="center"
                           show-overflow-tooltip
                           label="实验流程"></el-table-column>
          <el-table-column prop="rateList"
                           align="center"
                           show-overflow-tooltip
                           label="进度">
          </el-table-column>

          <el-table-column prop="sampleTime"
                           align="center"
                           width="140"
                           label="采样日期"></el-table-column>
          <!-- <el-table-column label="操作"
                           v-if="identity==1">
            <template v-slot="scope">
              <el-button type="text"
                         icon="el-icon-tickets"
                         @click="sampleDelete(scope.row.id)"
                         style="color:red;">删除</el-button>

            </template>
          </el-table-column> -->
        </el-table>
      </template>

      <!-- 分页导航 -->
      <template>
        <el-row class="pagination">
          <pagination v-show="total>0"
                      :total="total"
                      :pageSizes=pageSizes
                      :page.sync="queryParams.pageNum"
                      :limit.sync="queryParams.pageSize"
                      @pagination="getSampleList" />
        </el-row>
      </template>
    </el-card>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
export default {
  components: {
    Pagination
  },
  data() {
    return {
      // 时间
      value1: '',
      headers: {
        token: sessionStorage.getItem('token')
      },
      pageSizes: [50, 100, 200, 400],
      queryParams: {
        pageNum: 1,
        pageSize: 50,
        keyword: '',
        startTime: '',
        endTime: ''
      },
      // 表格数据
      tableData: [],
      // 分类下拉列表数据
      total: 0,
      identity: ''
    }
  },
  created() {
    if (this.$route.query.pageNum != 1 && this.$route.query.pageSize != undefined) {
      this.queryParams.pageNum = Number(this.$route.query.pageNum)
      this.queryParams.pageSize = Number(this.$route.query.pageSize)
    } else {
      this.queryParams.pageNum = 1
      this.queryParams.pageSize = 50
    }
  },
  mounted() {
    this.identity = window.sessionStorage.getItem('identity')
    this.getSampleList()
  },

  methods: {
    // 搜索按钮
    inquire() {
      if (this.value1 != null && this.value1 != '') {
        this.queryParams.startTime = this.dateFormat(this.value1[0])
        this.queryParams.endTime = this.dateFormat(this.value1[1])
      } else {
        this.queryParams.startTime = ''
        this.queryParams.endTime = ''
      }
      this.queryParams.pageNum = 1
      this.getSampleList()
    },
    // 删除样本
    async sampleDelete(id) {
      const msg = await this.$confirm('你确定要删除该样本吗?该操作会删除该样本的所有实验!', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
      if (msg === 'confirm') {
        const { data: res } = await this.$http.get(`/spl/sample/delSample?id=${id}`)
        //做删除操作
        if (res.code == 200) {
          this.$message.success('删除样本成功！')
        } else {
          this.$message.warning('删除样本失败')
        }
      }
      this.getSampleList()
    },
    // 创建时间查询开始
    dateFormat(dateData) {
      var date = new Date(dateData)
      var y = date.getFullYear()
      var m = date.getMonth() + 1
      m = m < 10 ? '0' + m : m
      var d = date.getDate()
      d = d < 10 ? '0' + d : d
      const time = y + '-' + m + '-' + d
      return time
    },

    // 获取样本列表
    async getSampleList() {
      const { data: res } = await this.$http.get('/spl/sample/getSampleList', { params: this.queryParams })
      if (res.code != 200) return this.$message.error(res.msg)
      this.tableData = res.data.list
      this.tableData.forEach((item) => {
        if (item.experimentProcessList != null) {
          item.experimentProcessList.forEach((item1, index) => {
            if (index == 0) {
              item.experimentProcessList[index] = item1
            } else {
              item.experimentProcessList[index] = '|' + ' ' + item1
            }
          })
        }
        if (item.rateList != null) {
          item.rateList.forEach((item2, index) => {
            if (index == item.rateList.length - 1) {
              item.rateList[index] = item2
            } else {
              item.rateList[index] = item2 + ' ' + '|' + ' '
            }
          })
        }
      })
      this.total = res.data.total
    },
    // 样本详情
    async updateHandler(_id, id) {
      this.$router.push({ path: '/sampleInfo', query: { id: _id, projectId: id, pageNum: this.queryParams.pageNum, pageSize: this.queryParams.pageSize, type: 1 } })
    },
    // 项目详情
    async toProject(id) {
      this.$router.push({ path: '/project/projectPrticulars', query: { id: id } })
    }
  }
}
</script>
<style lang='scss' scoped>
</style>
