<!-- 项目管理-->
<template>
  <div>
    <!-- 头部搜索框 -->

    <el-form :model="queryParams"
             ref="queryParams"
             size="small"
             :inline="true"
             label-width="68px">
      <el-form-item label="项目名称">
        <el-input siz="medium"
                  v-model="queryParams.keyword"
                  clearable
                  placeholder="请输入项目名称"
                  @input='inquire()'></el-input>
      </el-form-item>

      <el-form-item label="项目类型">
        <el-select size="medium"
                   v-model="queryParams.type"
                   placeholder="请选择项目类型"
                   clearable>
          <el-option label="LDT"
                     value="1"></el-option>
          <el-option label="CRO"
                     value="2"></el-option>
          <el-option label="RES"
                     value="3"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="创建时间">
        <el-date-picker v-model="value1"
                        type="daterange"
                        range-separator="————"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary"
                   size="medium"
                   icon="el-icon-search"
                   @click="inquire">搜索</el-button>
        <el-button @click="addPrincipal()"
                   type="primary"
                   plain
                   size="medium"
                   icon="el-icon-plus">添加项目</el-button>
      </el-form-item>
    </el-form>
    <!-- 数据表格 -->
    <template>
      <el-table :data="tableData"
                align="center"
                style="width: 100%"
                :header-cell-style="{background: '#4f71be',color: '#fff'}">
        <el-table-column prop="id"
                         align="center"
                         label="项目编号">
          <template slot-scope="scope">
            <span style="color:#429fff"
                  @click="updateHandler(scope.row.id)">{{scope.row.id}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="name"
                         align="center"
                         label="项目名"></el-table-column>
        <el-table-column prop="type"
                         align="center"
                         label="项目类型">
          <template slot-scope="scope">
            <span v-show="scope.row.type==1">LDT</span>
            <span v-show="scope.row.type==2">CRO</span>
            <span v-show="scope.row.type==3">RES</span>
          </template>
        </el-table-column>
        <el-table-column prop="introduction"
                         align="center"
                         show-overflow-tooltip
                         label="项目简介"></el-table-column>
        <el-table-column prop="createTime"
                         align="center"
                         label="项目创建日期"></el-table-column>
        <el-table-column prop="endTime"
                         align="center"
                         label="预期结束日期"></el-table-column>
        <el-table-column prop="username"
                         align="center"
                         label="项目负责人"></el-table-column>
        <!-- <el-table-column label="操作">
          <template v-slot="scope">
            <el-button type="text"
                       icon="el-icon-tickets"
                       @click="updateHandler(scope.row.id)">详情</el-button>
          </template>

        </el-table-column> -->
      </el-table>
    </template>

    <!-- 分页导航 -->
    <template>
      <el-row class="pagination">
        <pagination v-show="total>0"
                    :total="total"
                    :page.sync="queryParams.pageNum"
                    :limit.sync="queryParams.pageSize"
                    @pagination="getProjectList" />
      </el-row>
    </template>

    <!-- 添加项目对话框 -->
    <template>
      <el-dialog title="添加项目"
                 :visible.sync="addDialogVisible"
                 width="50%">
        <div class="demo-input-suffix">
          <el-form :model="recompose"
                   ref="recompose"
                   :rules="rules"
                   label-width="125px">
            <el-form-item label="项目名称"
                          prop="name">
              <el-input v-model="recompose.name"
                        placeholder="请输入项目名称"></el-input>
            </el-form-item>
            <el-form-item label="项目类型"
                          prop="type">
              <el-select size="medium"
                         v-model="recompose.type"
                         placeholder="请选择类型">
                <el-option label="LDT"
                           value="1"></el-option>
                <el-option label="CRO"
                           value="2"></el-option>
                <el-option label="RES"
                           value="3"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="项目简介"
                          prop="introduction">
              <el-input v-model="recompose.introduction"
                        type="textarea"
                        placeholder="请输入项目简介"></el-input>
            </el-form-item>

            <el-form-item label="预期结束日期"
                          prop="endTime">
              <el-date-picker type="date"
                              placeholder="选择日期"
                              v-model="recompose.endTime"
                              style="width: 100%;"></el-date-picker>
            </el-form-item>

          </el-form>
          <div style="font-size:12px;color:orangered;padding-left:20px">注意:添加完项目后，您就是项目的负责人，请分配项目权限给其他用户。</div>
        </div>
        <span slot="footer"
              class="dialog-footer">
          <el-button @click="addDialogVisible = false">取 消</el-button>
          <el-button type="success"
                     :disabled="buyFlag"
                     @click="submitHandler('recompose')">提交</el-button>
        </span>
      </el-dialog>
    </template>

  </div>
</template>

<script>
import { _debounce } from '@/utils/public'
import Pagination from '@/components/Pagination'

export default {
  components: {
    Pagination
  },
  data() {
    return {
      value1: '',
      headers: {
        token: sessionStorage.getItem('token')
      },
      // 搜索获取列表入参
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        keyword: '',
        startTime: '',
        endTime: '',
        type: ''
      },
      // 获取负责人数组
      userList: [],
      // 表格数据
      tableData: [],
      total: 0,
      // 添加对话框显示隐藏
      addDialogVisible: false,
      //添加产品信息
      recompose: {
        name: '',
        commander: '',
        introduction: '',
        endTime: '',
        type: ''
      },
      rules: {
        name: [
          { required: true, message: '请输入项目名称', trigger: 'change' },
          { min: 1, max: 20, message: '长度在 1到 20 个字符', trigger: 'blur' }
        ],
        type: [{ required: true, message: '请选择项目类型', trigger: 'change' }],
        introduction: { min: 1, max: 200, message: '请注意不能超过200个字符哦！', trigger: 'blur' }
      },
      buyFlag: false
    }
  },
  // 获取跳转前的页面
  created() {
    this.queryParams.keyword = this.$route.query.keyword
    this.queryParams.type = this.$route.query.type
    if (this.$route.query.pageNum != 1 && this.$route.query.pageNum != undefined && this.$route.query.pageNum != undefined) {
      this.queryParams.pageNum = Number(this.$route.query.pageNum)
      this.queryParams.pageSize = Number(this.$route.query.pageSize)
    } else {
      this.queryParams.pageNum = 1
      this.queryParams.pageSize = 10
    }
  },
  mounted() {
    this.getProjectList()
  },
  methods: {
    //获取项目负责人
    addPrincipal() {
      this.buyFlag = false
      this.addDialogVisible = true
    },
    // 搜索按钮
    inquire() {
      if (this.value1 != null && this.value1 != '') {
        this.queryParams.startTime = this.dateFormat(this.value1[0])
        this.queryParams.endTime = this.dateFormat(this.value1[1])
      } else {
        this.queryParams.startTime = ''
        this.queryParams.endTime = ''
      }
      this.queryParams.pageNum = 1
      this.getProjectList()
    },
    // 创建时间查询开始
    dateFormat(dateData) {
      var date = new Date(dateData)
      var y = date.getFullYear()
      var m = date.getMonth() + 1
      m = m < 10 ? '0' + m : m
      var d = date.getDate()
      d = d < 10 ? '0' + d : d
      const time = y + '-' + m + '-' + d
      return time
    },
    // 获取项目列表
    async getProjectList() {
      const { data: res } = await this.$http.get('/spl/project/getProjectList', { params: this.queryParams })
      if (res.code != 200) return this.$message.error(res.msg)
      this.tableData = res.data.list
      this.total = res.data.total
    },
    // 点击操作详情按钮
    async updateHandler(_id) {
      // const { data: res } = await this.$http.get(`/spl/projectAuth/getProjectAuth?projectId=${_id}`)
      // window.sessionStorage.setItem('encryptProjectId', res.data.encryptProjectId)
      this.$router.push({
        path: '/project/projectPrticulars',
        query: { id: _id, pageNum: this.queryParams.pageNum, pageSize: this.queryParams.pageSize, keyword: this.queryParams.keyword, type: this.queryParams.type }
      })
    },

    // 添加项目提交
    submitHandler(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.buyFlag = true
          const { data: res } = await this.$http.post(`/spl/project/addProject`, this.recompose)
          if (res.code == 200 && res.data == 1) {
            this.$message.success('添加成功')
          } else {
            this.$message.error(res.msg)
          }
          this.recompose = {}
          this.addDialogVisible = false
          this.getProjectList()
        }
      })
    }
  }
}
</script>
<style lang='scss' scoped>
.pagination {
  padding-top: 10px;
  text-align: right;
}
::v-deep .el-table .el-table__cell {
  padding: 4px 0;
}
</style>