<!--管理员信息  -->
<template>
  <div class="czls">
    <el-card class="box-card">
      <div slot="header"
           class="clearfix">
        <span>个人信息</span>
      </div>
      <div class="text item">用户名:{{ userInfo.username}}</div>
      <div class="text item">手机号:{{ userInfo.phone }}</div>
      <div class="text item">邮箱:{{ userInfo.email }}</div>
      <template>
        <el-row class="btns-curd"
                :gutter="10"
                align="middle"
                type="flex">
          <el-col :span="1.5">
            <el-button @click="addDialogVisible = true"
                       type="primary"
                       plain
                       size="mini"
                       icon="el-icon-s-tools">修改密码</el-button>
          </el-col>
          <el-col :span="1.5">
            <el-button @click="updAdminInfo"
                       type="success"
                       plain
                       size="mini"
                       icon="el-icon-user">修改信息</el-button>
          </el-col>

        </el-row>
      </template>
    </el-card>
    <!-- 修改密码对话框 -->
    <template>
      <el-dialog title="修改密码"
                 :visible.sync="addDialogVisible"
                 width="35%">
        <div class="demo-input-suffix">
          <el-form :model="recompose"
                   ref="recompose"
                   :rules="rules"
                   label-width="80px">
            <!-- :model="form" -->
            <el-form-item label="原密码"
                          prop="oldPassword">
              <el-input v-model="recompose.oldPassword"
                        placeholder="请输入原密码"
                        show-password>></el-input>
            </el-form-item>
            <el-form-item label="新密码"
                          prop="newPassword">
              <el-input v-model="recompose.newPassword"
                        placeholder="请输入新密码"
                        show-password>></el-input>
            </el-form-item>
            <el-form-item label="新密码"
                          prop="newPassword2">
              <el-input v-model="recompose.newPassword2"
                        placeholder="请再次输入新密码"
                        show-password>></el-input>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer"
              class="dialog-footer">
          <el-button @click="addDialogVisible = false">取 消</el-button>
          <el-button type="primary"
                     @click="submitHandler('recompose')">确 定</el-button>
        </span>
      </el-dialog>
    </template>
    <!-- 修改用户名对话框 -->
    <template>
      <el-dialog title="修改信息"
                 :visible.sync="alteruser"
                 width="35%">
        <div class="demo-input-suffix">

          <el-form :model="userdata"
                   ref="userdata"
                   :rules="rules"
                   label-width="80px">
            <!-- :model="form" -->
            <el-form-item label="用户名"
                          prop="username">
              <el-input v-model="userdata.username"
                        placeholder="请输入新用户名"></el-input>
            </el-form-item>
            <el-form-item label="手机号"
                          prop="phone">
              <el-input v-model="userdata.phone"
                        placeholder="请输入新手机号"></el-input>
            </el-form-item>
            <el-form-item label="邮箱"
                          prop="email">
              <el-input v-model="userdata.email"
                        placeholder="请输入邮箱"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <span style="color:coral;margin-left: 15%;">请至少选择一项修改</span>
        <span slot="footer"
              class="dialog-footer">
          <el-button @click="alteruser = false">取 消</el-button>
          <el-button type="primary"
                     @click="submituser('userdata')">提 交</el-button>
        </span>
      </el-dialog>
    </template>
  </div>

</template>

<script>
import BaseTable from '@/components/BaseTable'
export default {
  components: {
    BaseTable
  },
  data() {
    return {
      //查询管理员传参
      queryParams: {
        pageNum: 1,
        pageSize: 10
      },
      total: 0,
      // 控制修改密码
      addDialogVisible: false,
      // 控制修改用户名
      alteruser: false,
      // 修改用户信息传参
      userdata: {
        username: '',
        phone: '',
        email: ''
      },
      recompose: {
        oldPassword: '',
        newPassword: '',
        newPassword2: ''
      },
      passwordList: {
        password: '',
        passwordOld: ''
      },
      // 新增信息
      xzdata: false,
      // 新增传参
      xzsj: {
        username: '',
        password: '',
        identity: ''
      },
      userInfo: {},
      headers: {
        token: sessionStorage.getItem('token')
      },
      rules: {
        username: [{ required: true, message: '请输入用户名', trigger: 'blur' }],
        phone: [{ min: 11, max: 11, message: '请输入11位的手机号', trigger: 'blur' }],
        email: [{ type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }],
        oldPassword: [
          { required: true, message: '请输入原密码', trigger: 'blur' },
          { min: 6, max: 20, message: '密码长度在 6 到 20 个字符', trigger: 'blur' }
        ],
        newPassword: [
          { required: true, message: '请输入新密码', trigger: 'blur' },
          { min: 6, max: 20, message: '密码长度在 6 到 20 个字符', trigger: 'blur' }
        ],
        newPassword2: [
          { required: true, message: '请确认新密码', trigger: 'blur' },
          { min: 6, max: 20, message: '密码长度在 6 到 20 个字符', trigger: 'blur' }
        ]
      }
    }
  },
  mounted() {
    this.getAdminInfo()
  },
  methods: {
    updAdminInfo() {
      this.alteruser = true
      console.log(this.userInfo)
      this.userdata.phone = this.userInfo.phone
      this.userdata.username = this.userInfo.username
      this.userdata.email = this.userInfo.email
    },
    qxUpload() {
      this.$refs.upload.clearFiles()
    },
    submitUpdateAvatar() {
      this.queryParams.type = 3
      this.$refs.upload.submit()
      this.$refs.upload.clearFiles()

      this.getAdminInfo()
    },
    // 获取用户信息
    async getAdminInfo() {
      this.recompose.type = 1
      console.log(this.recompose)
      const { data: res } = await this.$http.get(`/spl/user/getUserInfo`)
      this.userInfo = res.data
    },
    submitHandler(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          if (this.recompose.newPassword != this.recompose.newPassword2) {
            this.$message.error('两次新密码不同')
            return
          } else if (this.recompose.newPassword == '') {
            this.$message.error('新密码不为空')
            return
          } else if (this.recompose.oldPassword == '') {
            this.$message.error('原密码不为空')
            return
          } else {
            this.passwordList.passwordOld = this.recompose.oldPassword
            this.passwordList.password = this.recompose.newPassword2
          }

          console.log(this.recompose)
          const { data: res } = await this.$http.post('/spl/user/modifyUserInfo', this.passwordList)
          console.log(res)
          if (res.code !== 200) return this.$message.error(res.msg)
          if (res.code == 200) {
            alert('密码修改成功，请重新登录')

            window.sessionStorage.removeItem('token')
            window.sessionStorage.removeItem('tokenStartTime')
            this.$router.push('/')
          }
          this.addDialogVisible = false
        }
      })
    },
    // 修改用户名
    submituser(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const { data: res } = await this.$http.post('/spl/user/modifyUserInfo', this.userdata)
          console.log(res)
          this.alteruser = false
          this.userdata.username = ''
          this.getAdminInfo()
          if (res.code !== 200) return this.$message.error(res.msg)
          if (res.code == 200) return this.$message.success('修改成功')
        }
      })

      //关闭弹窗
    },
    open4() {
      this.xzdata = true
    },
    // 修改状态
    async updateBrandStatus(row) {
      console.log(row)
      const { data: res } = await this.$http.post(`/admin/admin/setAdminStatus?id=${row.id}&status=${row.status}`)
    },
    check(data) {
      console.log('点击链接', data)
      this.$router.push({ path: '/sampleInfo' })
    },
    check1(data) {
      console.log('点击链接', data)
      this.$router.push({ path: '/project/projectPrticulars' })
    }
  }
}
</script>
<style lang="scss" scoped>
img {
  margin-left: 10%;
}
.text {
  font-size: 14px;
}

.item {
  margin-bottom: 18px;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}

.box-card {
  width: 480px;
  margin-right: 40px;
  float: left;
}
.link {
  color: black;
}
// .czls {
//   display: flex;
// }
</style>
