<!-- 登录 -->
<template>
  <div class="app">
    <div class="login">
      <!-- 主体 -->
      <div class="el-form login-form">
        <h2 class="title">GOMICS-LIMS</h2>
        <el-form :model="ruleForm"
                 ref="ruleForm"
                 :rules="rules"
                 label-width="100px"
                 class="demo-ruleForm">
          <el-form-item prop="username"
                        label-width="auto">
            <el-input v-model="ruleForm.username"
                      prefix-icon="el-icon-user-solid"
                      placeholder="请输入用户名"></el-input>
          </el-form-item>
          <el-form-item prop="password"
                        label-width="auto">
            <el-input type="password"
                      v-model="ruleForm.password"
                      prefix-icon="el-icon-lock"
                      placeholder="请输入密码"
                      show-password
                      @keyup.enter.native="login"></el-input>
          </el-form-item>

          <el-button type="primary"
                     @click="login">登录</el-button>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ruleForm: {
        username: '',
        password: ''
      },
      wz: {
        type: '0',
        pageSize: '5',
        pageNum: '5'
      },
      rules: {
        username: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
          { min: 0, max: 100, message: '用户名不能为空', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 0, max: 100, message: '密码不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    login() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (!valid) return
        const { data: res } = await this.$http.post('/spl/user/login', this.ruleForm)
        if (res.code !== 200) return this.$message.error(res.msg)
        this.$message.success('登陆成功')
        window.sessionStorage.setItem('tokenStartTime', new Date().getTime())
        window.sessionStorage.setItem('token', res.data.token)
        window.sessionStorage.setItem('identity', res.data.identity)
        this.$router.push('/project')
      })
    }
  }
}
</script>
<style lang='scss' scoped>
.app {
  height: 100%;
  .login {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-image: url('../assets/images/scienceBanner2.png');
    background-size: cover;
  }
}
.login-form {
  border-radius: 6px;
  background: #fff;
  width: 400px;
  padding: 25px 25px 5px 25px;
  opacity: 0.94;

  .title {
    margin: 0 auto 30px auto;
    text-align: center;
    color: #707070;
  }
  .el-button {
    margin: 20px auto;
    width: 100%;
  }
}
.el-login-footer {
  height: 40px;
  line-height: 40px;
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
  color: #fff;
  font-family: Arial;
  font-size: 12px;
  letter-spacing: 1px;
}
</style>