<!--首页 -->
<template>
  <div class="information">
    欢迎━(*｀∀´*)ノ亻!
  </div>
</template>

<script>
export default {
  data() {
    return {}
  }
}
</script>
<style lang='scss' scoped>
.information .sum {
  width: 100%;
  height: 100px;
  margin-top: 10px;
  display: flex;

  .sumData {
    width: 30%;
    height: 100px;
    background: #55b566;
    margin-left: 30px;
    border-radius: 10px;
    .sumData-quantity {
      color: #fff;
      font-size: 20px;
      margin: 20px 0px 0px 20px;
      .figure {
        margin-top: 15px;
      }
    }
  }
}
.fiche {
  display: flex;
  .card {
    padding: 0;
    margin: 10px 0px 0px 10px;
    width: 48%;
    height: 400px;
  }
}
</style>