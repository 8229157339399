<!-- 用户管理模块 -->
<template>
  <div>
    <el-form :model="queryParams"
             ref="queryParams"
             size="small"
             :inline="true"
             label-width="78px">
      <el-form-item>
        <el-button type="success"
                   size="medium"
                   icon="el-icon-plus"
                   @click="addDialogVisible=true">添加用户</el-button>
      </el-form-item>
    </el-form>

    <!-- 添加用户对话框 -->
    <template>
      <el-dialog title="添加用户"
                 :visible.sync="addDialogVisible"
                 width="50%">
        <div class="demo-input-suffix">
          <el-form :model="recompose"
                   ref="recompose"
                   :rules="rules"
                   label-width="125px">
            <el-form-item label="用户名"
                          prop="username">
              <el-input v-model="recompose.username"
                        placeholder="请输入用户名"></el-input>
            </el-form-item>
            <el-form-item label="密码"
                          prop="password">
              <el-input v-model="recompose.password"
                        show-password
                        placeholder="请输入密码"></el-input>
            </el-form-item>
            <el-form-item label="手机号"
                          prop="phone">
              <el-input v-model="recompose.phone"
                        placeholder="请输入手机号"></el-input>
            </el-form-item>
            <el-form-item label="邮箱"
                          prop="email">
              <el-input v-model="recompose.email"
                        placeholder="请输入邮箱"></el-input>
            </el-form-item>

          </el-form>
        </div>
        <span slot="footer"
              class="dialog-footer">
          <el-button @click="addDialogVisible = false">取 消</el-button>
          <el-button type="success"
                     @click="register('recompose')">提交</el-button>
        </span>
      </el-dialog>
    </template>

    <el-card class="box-card">
      <!-- 数据表格 -->
      <template>
        <el-table :data="userList"
                  align="center"
                  style="width: 100%"
                  :header-cell-style="{background: '#4f71be',color: '#fff'}">
          <el-table-column prop="id"
                           align="center"
                           label="用户编号">
          </el-table-column>
          <el-table-column prop="username"
                           align="center"
                           label="用户名"></el-table-column>
          <el-table-column prop="phone"
                           align="center"
                           label="手机号"></el-table-column>
          <el-table-column prop="email"
                           align="center"
                           label="邮箱"></el-table-column>
          <el-table-column prop="status"
                           align="center"
                           label="状态"
                           width="150">
            <template slot-scope="scope">
              <el-switch v-model="scope.row.status"
                         active-color="#13ce66"
                         inactive-color="#ff4949"
                         :active-value="0"
                         :inactive-value="1"
                         active-text="正常"
                         inactive-text="禁用"
                         @change="modifyUserStatus(scope.row)"></el-switch>
            </template>
          </el-table-column>
        </el-table>
      </template>

      <!-- 分页导航 -->
      <template>
        <el-row class="pagination">
          <pagination v-show="total>0"
                      :total="total"
                      :page.sync="queryParams.pageNum"
                      :limit.sync="queryParams.pageSize"
                      @pagination="getUserList" />
        </el-row>
      </template>
    </el-card>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
export default {
  components: {
    Pagination
  },
  data() {
    return {
      queryParams: {
        pageSize: 10,
        pageNum: 1
      },
      total: 0,
      userList: [],
      addDialogVisible: false,
      recompose: {
        username: '',
        password: '',
        phone: '',
        email: ''
      },
      rules: {
        username: [{ required: true, message: '请输入用户名', trigger: 'change' }],
        password: [{ required: true, message: '请输入密码', trigger: 'change' }],
        email: [{ type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }],
        phone: [{ min: 11, max: 11, message: '请输入正确的手机号', trigger: 'blur' }]
      }
    }
  },
  mounted() {
    this.getUserList()
  },

  methods: {
    // 获取用户列表
    async getUserList() {
      const { data: res } = await this.$http.get('/spl/manage/user/getUserList', { params: this.queryParams })
      this.userList = res.data.list
      this.total = res.data.total
    },
    register(formName) {
      console.log(this.$refs[formName])
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const { data: res } = await this.$http.post('/spl/manage/user/register', this.recompose)
          if (res.data == 1 && res.code == 200) {
            this.addDialogVisible = false
            this.$message.success('添加成功')
          } else {
            this.$message.error(res.msg)
          }
          this.getUserList()
        }
      })
    },
    async modifyUserStatus(row) {
      const { data: res } = await this.$http.get(`/spl/manage/user/modifyUserStatus?id=${row.id}&status=${row.status}`)
      console.log(res)
    }
  }
}
</script>
<style lang='scss' scoped>
</style>