<!--步骤模板管理 -->
<template>
  <div>
    <div style="margin:10px">
      <el-button type="success"
                 @click="openAddTemplate">添加模板步骤</el-button>
      <el-button type="primary"
                 @click="addAttributeShow">添加属性</el-button>
    </div>
    <el-card>
      <el-table :data="stepTemplateList"
                align="center"
                style="width: 100%"
                :header-cell-style="{background: '#4f71be',color: '#fff'}">
        <el-table-column label="步骤编号"
                         align="center"
                         prop="id">
        </el-table-column>
        <el-table-column label="步骤名称"
                         align="center"
                         prop="name">
        </el-table-column>
        <el-table-column width="650"
                         align="center"
                         label="属性"
                         prop="attributeModelList">
          <template slot-scope="scope">
            <el-tag v-for="item in scope.row.attributeModelList"
                    :key="item.id"
                    type="info"
                    disable-transitions> {{item.name}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作"
                         align="center">
          <template slot-scope="scope">
            <el-button type="text"
                       icon="el-icon-edit"
                       @click="openDialogBox(scope.row)"
                       style="color:#409eff;">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <!-- 修改属性 -->
    <template>
      <el-dialog title="修改模板步骤属性"
                 :visible.sync="openProperty"
                 width="50%">
        <div>
          <template>
            <el-transfer target-order=push
                         filterable
                         @change="handleChange"
                         :titles="['未选属性', '已选属性']"
                         :filter-method="filterMethod"
                         filter-placeholder="请输入属性名称"
                         v-model="value"
                         :data="data">
            </el-transfer>
          </template>
        </div>
        <span slot="footer"
              class="dialog-footer">
          <el-button @click="openProperty = false">取 消</el-button>
          <el-button type="success"
                     @click="modifyTemplateStage">提交</el-button>
        </span>
      </el-dialog>
    </template>

    <!-- 添加属性 -->
    <template>
      <el-dialog title="添加属性"
                 :visible.sync="addAttributeFlag"
                 width="50%">
        <div>
          <el-form :model="attributeParams"
                   ref="attributeParams"
                   :rules=rules
                   label-width="135px">
            <el-form-item label="属性名称"
                          prop="name">
              <el-input v-model="attributeParams.name"
                        placeholder="请输入属性名称"></el-input>
            </el-form-item>
            <el-form-item label="属性英文名称"
                          prop="nameEnglish">
              <el-input v-model="attributeParams.nameEnglish"
                        placeholder="请输入属性英文名称,请以大写字母开头(如:'UserName')"></el-input>
            </el-form-item>
            <el-form-item label="类型"
                          prop="type">
              <el-select size="medium"
                         v-model="attributeParams.type"
                         placeholder="请选择类型">
                <el-option label="文本"
                           value="0"></el-option>
                <el-option label="图片"
                           value="1"></el-option>

              </el-select>
            </el-form-item>

          </el-form>
        </div>
        <span slot="footer"
              class="dialog-footer">
          <el-button @click="addAttributeFlag = false">取 消</el-button>
          <el-button type="success"
                     @click="addAttribute('attributeParams')">提交</el-button>
        </span>
      </el-dialog>
    </template>

    <!-- 添加模板步骤 -->
    <template>
      <el-dialog title="添加模板步骤"
                 :visible.sync="addTemplateStep"
                 width="50%">
        <div>
          <el-form :model="addTemplateList"
                   ref="addTemplateList"
                   :rules=rules
                   label-width="135px">
            <el-form-item label="步骤名称"
                          prop="name">
              <el-input v-model="addTemplateList.name"
                        placeholder="请输入步骤名称"></el-input>
            </el-form-item>
            <el-form-item label="步骤属性">
              <template>
                <el-transfer target-order=push
                             filterable
                             @change="handleChangeStepTemplate"
                             :titles="['未选属性', '已选属性']"
                             :filter-method="filterMethod"
                             filter-placeholder="请输入属性名称"
                             v-model="addStepValue"
                             :data="addStepData">
                </el-transfer>
              </template>
            </el-form-item>
          </el-form>
        </div>

        <span slot="footer"
              class="dialog-footer">
          <el-button @click="addTemplateStep = false">取 消</el-button>
          <el-button type="success"
                     @click="addTemplateStage('addTemplateList')">提交</el-button>
        </span>
      </el-dialog>
    </template>

  </div>
</template>

<script>
export default {
  data() {
    return {
      // 修改属性弹出框
      openProperty: false,
      // 添加属性
      addAttributeFlag: false,
      stepTemplateList: [],
      // 未选属性数组
      attributeList: [],
      // 添加属性穿梭框（未选属性）
      data: [],
      // 添加模板步骤穿梭框（未选属性）
      addStepData: [],
      // 添加属性穿梭框（已选属性）
      value: [],
      // 添加模板步骤穿梭框（已选属性）
      addStepValue: [],
      changeAttributeList: [],
      allAttributeList: [],
      stageUpdateObj: {
        templateStageId: '',
        attributeIdArray: []
      },
      attributeParams: {
        type: '',
        nameEnglish: '',
        name: ''
      },
      // 穿梭框搜索
      filterMethod(query, item) {
        return item.label.indexOf(query) > -1
      },
      rules: {
        name: [{ required: true, message: '请输入属性名称', trigger: 'blur' }],
        nameEnglish: [{ required: true, message: '请输入属性英文名称', trigger: 'blur' }],
        type: [{ required: true, message: '请选择类型', trigger: 'change' }]
      },
      // 添加模板步骤对话框
      addTemplateStep: false,
      // 添加模板步骤入参
      addTemplateList: {
        name: '',
        attributeIdArray: ''
      },
      allAddStepList: [],
      changeAddStepList: []
    }
  },
  mounted() {
    this.getTemplateStageList()
  },

  methods: {
    async getTemplateStageList() {
      const { data: res } = await this.$http.get('/spl/manage/template/getTemplateStageList')
      console.log(res)
      this.stepTemplateList = res.data
    },
    // 点击编辑
    async openDialogBox(row) {
      this.stageUpdateObj.templateStageId = row.id
      this.data = []
      this.value = []
      this.openProperty = true
      var selectedList = []
      row.attributeModelList.forEach((att, index) => {
        selectedList.push(att.id)
      })
      var resultList = selectedList
      const { data: res } = await this.$http.get(`/spl/manage/template/getAttributeList`)
      res.data.forEach((att, index) => {
        if (selectedList.indexOf(att.id) != -1) {
          var ind = selectedList.indexOf(att.id)
          resultList[ind] = index
        }
        this.allAttributeList.push(att.id)
        this.data.push({
          label: att.name,
          key: index,
          id: att.id
        })
      })
      this.value = resultList
    },
    // 修改属性提交
    async modifyTemplateStage() {
      if (this.changeAttributeList <= 0) {
        return this.$message.error('已选属性不能为空')
      }
      this.stageUpdateObj.attributeIdArray = this.changeAttributeList
      const { data: res } = await this.$http.post('/spl/manage/template/modifyTemplateStage', this.stageUpdateObj)
      if (res.code == 200 && res.data == 1) {
        this.openProperty = false
        this.$message.success('修改属性成功')
        this.getTemplateStageList()
      } else {
        this.$message.error(res.msg)
      }
    },
    handleChange(value) {
      this.changeAttributeList = []
      value.forEach((item) => {
        this.changeAttributeList.push(this.allAttributeList[item])
      })
    },
    addAttributeShow() {
      this.openProperty = false
      this.addAttributeFlag = true
    },
    // 添加属性--提交按钮
    addAttribute(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const { data: res } = await this.$http.post('/spl/manage/template/addAttribute', this.attributeParams)
          if (res.code == 200 && res.data == 1) {
            this.addAttributeFlag = false
            this.$message.success('添加属性成功')
            this.attributeParams = {}
          } else {
            this.$message.error(res.msg)
          }
        }
      })
    },
    handleChangeStepTemplate(value) {
      this.changeAddStepList = []
      console.log(this.changeAddStepList)
      value.forEach((item) => {
        this.changeAddStepList.push(this.allAddStepList[item])
      })
    },
    // 打开添加模板步骤
    async openAddTemplate() {
      this.addStepData = []
      this.addStepValue = []
      this.addTemplateStep = true
      const { data: res } = await this.$http.get(`/spl/manage/template/getAttributeList`)
      console.log(res.data)
      res.data.forEach((att, index) => {
        this.allAddStepList.push(att.id)
        this.addStepData.push({
          label: att.name,
          key: index,
          id: att.id
        })
      })
    },
    addTemplateStage(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          if (this.changeAddStepList <= 0) {
            return this.$message.error('已选属性不能为空')
          }
          this.addTemplateList.attributeIdArray = this.changeAddStepList
          const { data: res } = await this.$http.post('/spl/manage/template/addTemplateStage', this.addTemplateList)
          if (res.code == 200 && res.data == 1) {
            this.addTemplateStep = false
            this.getTemplateStageList()
            this.$message.success('添加步骤成功')
            this.addTemplateList = {}
          } else {
            this.$message.error(res.msg)
          }
        }
      })
    }
  }
}
</script>
<style lang='scss' scoped>
.demo-table-expand {
  font-size: 0;
}
.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}
.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 100%;
  display: flex;
}
.el-tag {
  margin: 5px;
}
::v-deep.el-form--inline .el-form-item__label {
  width: 110px;
}
::v-deep.el-table td.el-table__cell div {
  width: 100%;
}

::v-deep .el-input {
  width: 90%;
}
</style>