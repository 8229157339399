<template>
  <div id="Wrap">
    <!-- 表格 -->
    <el-table @selection-change="selectionChange"
              class="table"
              :header-cell-style="{background: '#4f71be',color: '#fff'}"
              :data="tableData"
              @filter-change="filterChange"
              v-loading="loading"
              element-loading-text="拼命加载中"
              element-loading-spinner="el-icon-loading"
              :border="hasBorder">
      <el-table-column type="selection"
                       width="50"
                       v-if="selectionShow"> </el-table-column>
      <!-- 其他 -->
      <template v-for="item in tableColumnOptions">
        <!-- 插槽列 -->
        <el-table-column v-if="item.columnType"
                         :key="item.label"
                         :prop="item.name"
                         :label="item.label"
                         :width="item.width"
                         :filters="item.filters"
                         :column-key="item.columnKey"
                         :show-overflow-tooltip="item.lineFeed"
                         :header-align="item.headerAlign || 'center'"
                         :align="item.align || 'center'">
          <template slot-scope="{ row }">
            <slot :name="item.soltName"
                  :data="row">
            </slot>
          </template>
        </el-table-column>
        <!-- 非插槽列 -->
        <el-table-column v-else
                         :key="item.label"
                         :prop="item.name"
                         :label="item.label"
                         :width="item.width"
                         :show-overflow-tooltip="item.lineFeed"
                         :header-align="item.headerAlign || 'center'"
                         :align="item.align || 'center'">
        </el-table-column>
      </template>
    </el-table>
  </div>
</template>
<script type="text/ecmascript-6">
export default {
  name: 'BaseTable',
  props: {
    // 表格数据
    tableData: {
      type: Array,
      default() {
        return []
      }
    },
    // 表格列项
    tableColumnOptions: {
      type: Array,
      require: true,
      default() {
        return []
      }
    },
    // 表格数据总量
    tableDataTotal: {
      type: Number,
      default() {
        return 0
      }
    },
    // 是否具有索引
    hasIndex: {
      type: Boolean,
      default() {
        return true
      }
    },
    // 是否显示边框
    hasBorder: {
      type: Boolean,
      default() {
        return false
      }
    },
    // 是否显示选择框
    selectionShow: {
      type: Boolean,
      default() {
        return true
      }
    },
    // 是否显示选分页器
    paginationShow: {
      type: Boolean,
      default() {
        return true
      }
    },
    //是否显示加载中
    loading: {
      type: Boolean,
      default() {
        return true
      }
    }
  },
  data() {
    return {
      // 表格当前页数
      currentPage: 1,
      // 表格每页数量
      pageSize: 10
    }
  },
  computed: {
    // 计算是否显示分页器
    isShowPagination() {
      const isShow = this.tableDataTotal === 0
      return isShow
    }
  },
  methods: {
    handleCurrentChange(val) {
      const params = {
        currentPage: val,
        pageSize: this.pageSize
      }
      this.$emit('tableUpdate', params)
    },
    // 修改每页数量
    handleSizeChange(val) {
      const params = {
        currentPage: this.currentPage,
        pageSize: val
      }
      this.$emit('tableUpdate', params)
    },
    // 选择框改变
    selectionChange(e) {
      this.$emit('selectionChange', e)
    },
    filterChange(filterObj) {
      this.$emit('filterChange', filterObj)
    }
  }
}
</script>
<style  scoped>
#Wrap {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background-color: #fff;
  display: flex;
  flex-direction: column;
}
#Wrap .pagination {
  margin-top: auto;
  margin-left: auto;
}
>>> .cell {
  text-align: center;
}
>>> .cell #link {
  font-family: MicrosoftYaHei;
  color: #445af7;
  cursor: pointer;
}
>>> .cell #delete {
  color: #ff5b5b;
  margin-left: 35px;
  cursor: pointer;
}
>>> .color-row {
  background-color: rgb(243, 243, 243);
}
>>> .el-table--border::after,
.el-table--group::after,
.el-table::before {
  content: '';
  position: absolute;
  background-color: transparent !important;
  z-index: 1;
}
</style>