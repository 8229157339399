<!-- 样本详情 -->
<template>
  <div class="sampleDetails">
    <!-- 样本信息 -->
    <div class="project">
      <h3 class="projectTime"
          @click="back"><i class="el-icon-arrow-left"></i>返回</h3>
      <div class="sample">
        <!-- 左 -->
        <div class="sampleLeft">
          <el-card class="box-card">
            <div class="sampleID">
              <div class="sampleCode">
                <div style="white-space:nowrap;">项目名称：</div>
                <div @click='goOrderInfo(sampleInfoData.projectSimpleInfoRes.id)'
                     class="projectName">{{sampleInfoData.projectSimpleInfoRes.name}}</div>
              </div>
              <div class="sampleType">
                <div>项目类型：</div>
                <div>{{sampleInfoData.projectSimpleInfoRes.type==1?'LDT':sampleInfoData.projectSimpleInfoRes.type==2?'CRO':'RES'}}</div>
              </div>
              <div class="sampleCode">
                <div>完成实验数量:</div>
                <div>{{sampleInfoData.finishExperimentCount}}</div>
              </div>
              <div class="sampleCode">
                <div>全部实验数量:</div>
                <div>{{sampleInfoData.allExperimentCount}}</div>
              </div>
              <div class="sampleCode">
                <div>待完成实验数量:</div>
                <div>{{sampleInfoData.allExperimentCount-sampleInfoData.finishExperimentCount}}</div>
              </div>
              <div class="sampleCode">
                <div>样本流程进度:</div>
                <div>{{sampleInfoData.allRate+'%'}}</div>
              </div>
            </div>
            <div class="sampleListMiddle">
              <h3>样本列表</h3>
              <div>
                <el-button v-if="this.jurisdiction!=1"
                           type="success"
                           size="small"
                           @click="addFlow()"><i class="el-icon-plus"></i>添加实验流程</el-button>
              </div>
            </div>
            <el-divider class="frame"></el-divider>
            <BaseTable :has-index="true"
                       :has-border="false"
                       :table-data="sampleSimpleInfoResList"
                       :loading="loading"
                       :table-data-total="sampleSimpleInfoResList.length"
                       :table-column-options="tableColumnOptions"
                       :selectionShow=false>
              <!-- 数据交付 -->
              <template #isConfirm="{ data }">
                <div class="tag">
                  <el-tag type="success"
                          v-if="data.isConfirm == 1">已交付</el-tag>
                  <el-tag type="danger"
                          v-if="data.isConfirm == 0">未交付</el-tag>
                </div>
              </template>
              <!-- 产品类型 -->
              <template #productName="{ data }">
                <div class="tag">
                  <span>{{data.productName}}</span>
                </div>
              </template>
              <!-- 操作插槽 -->
              <template #operation="{ data }">
                <el-button v-if="identity==1||jurisdiction==3"
                           type="text"
                           icon="el-icon-edit"
                           @click="openModification(data)">编辑流程</el-button>

              </template>
              <!-- 客户编号插槽 -->
              <template #link="{ data }">
                <span class="link"
                      @click="updateHandler(data.customerNumber)">{{data.customerNumber}}</span>
              </template>
              <!-- 肺癌插槽 -->
              <template #lungCancerResult="{ data }">
                <span v-if="data.lungCancerResult!=null">
                  <span :class="data.lungCancerResult.search('阳')!=-1?'obvious':''">{{data.lungCancerResult}}</span>
                </span>
                <span v-else>
                  <span>{{data.lungCancerResult}}</span>
                </span>
              </template>
              <!-- 肝癌插槽 -->
              <template #liverCancerResult="{ data }">
                <span v-if="data.liverCancerResult!=null">
                  <span :class="data.liverCancerResult.search('阳')!=-1?'obvious':''">{{data.liverCancerResult}}</span>
                </span>
                <span v-else>
                  <span>{{data.liverCancerResult}}</span>
                </span>
              </template>
              <!-- 备注插槽 -->
              <template #remarks="{ data }">
                <span class="remarks"
                      @click="check(data)">{{data.remarks}}</span>
              </template>
            </BaseTable>
          </el-card>
        </div>
      </div>

    </div>

    <!-- 添加实验流程对话框 -->
    <template>
      <el-dialog title="添加实验流程"
                 :visible.sync="addExperimentalProcedure"
                 width="50%">
        <div class="demo-input-suffix1">
          <div>
            <div>
              <el-form :model="addExperimentProcesslist"
                       ref="addExperimentProcesslist"
                       :rules="rules"
                       label-width="125px">
                <el-form-item label="实验流程模板"
                              prop="templateProcessId">
                  <el-select size="medium"
                             @change='experimentList'
                             v-model="addExperimentProcesslist.templateProcessId"
                             placeholder="请选择流程">
                    <el-option v-for="(item,index) in experimentalProcedureData"
                               :key="item.id"
                               :label="item.name"
                               :value="index"></el-option>

                  </el-select>
                </el-form-item>
              </el-form>
            </div>
            <div style="margin-left:30px">
              <h3 style="margin-bottom:10px">操作样本</h3>
              <el-table :data="sampleInfoData.sampleSimpleInfoResList"
                        ref="dataTable"
                        @selection-change="handleSelectionChange"
                        align="center"
                        border
                        style="width: 100%">
                <el-table-column type="selection"
                                 width="35">
                </el-table-column>
                <el-table-column prop="id"
                                 label="编号"
                                 width="150">
                </el-table-column>
                <el-table-column prop="customerName"
                                 label="客户名称"
                                 width="150">
                </el-table-column>
              </el-table>
            </div>

          </div>

          <ol start=number>
            <h3 v-if="stageNameList.length>0"
                class="stageNameList">实验步骤：</h3>
            <li class="stageNameList"
                v-for="(item,index) in stageNameList"
                :key="item.index">{{index+1}}.{{item}}</li>
          </ol>
        </div>
        <span slot="footer"
              class="dialog-footer">
          <el-button @click="addExperimentalProcedure = false">取 消</el-button>
          <el-button type="success"
                     @click="addExperimentProcess('addExperimentProcesslist')">提交</el-button>
        </span>
      </el-dialog>
    </template>
    <!-- 编辑流程信息对话框 -->
    <template>
      <el-dialog title="编辑流程"
                 :visible.sync="modificationSample"
                 width="50%">
        <div class="flow">
          <h3 style="margin-bottom:10px">流程列表</h3>
          <el-table :data="compileSampleList"
                    ref="dataTable"
                    @selection-change="handleSelectionChange"
                    align="center"
                    border
                    style="width: 100%">

            <el-table-column prop="id"
                             label="流程编号">
            </el-table-column>
            <el-table-column prop="name"
                             label="流程名称">
            </el-table-column>
            <el-table-column label="进度">
              <template slot-scope="scope">
                <span>{{scope.row.finishStageCount}}/{{scope.row.allStageCount}}</span>
              </template>
            </el-table-column>
            <el-table-column label="操作"
                             v-if="identity==1||this.jurisdiction==3">
              <template slot-scope="
                             scope">
                <el-button type="text"
                           @click="delExperimentProcess(scope.row.id,scope.$index)"><i class="el-icon-delete"></i>移除</el-button>
              </template>

            </el-table-column>
          </el-table>

        </div>
        <span slot="footer"
              class="dialog-footer">

          <el-button @click="modificationSample = false">关闭</el-button>

        </span>
      </el-dialog>
    </template>

    <!-- 编辑按钮 -->
    <template>
      <el-dialog title="修改样本信息"
                 :visible.sync="sampleInfo"
                 width="50%">
        <div class="demo-input-suffix">
          <el-form :model="modifySampleInfoList"
                   ref="modifySampleInfoList"
                   label-width="135px">

            <el-form-item label="结束时间"
                          prop="endTime">
              <el-date-picker type="date"
                              :clearable=false
                              placeholder="选择日期"
                              v-model="modifySampleInfoList.endTime"
                              style="width: 100%;"></el-date-picker>
            </el-form-item>
            <el-form-item label="样本编号"
                          prop="newId">
              <el-input v-model="modifySampleInfoList.newId"
                        placeholder="请输入新样本编号"></el-input>
            </el-form-item>
            <el-form-item label="客户名称"
                          prop="customerName">
              <el-input v-model="modifySampleInfoList.customerName"
                        placeholder="请输入客户名称"></el-input>
            </el-form-item>
            <el-form-item label="收样时间"
                          prop="sampleTime">
              <el-date-picker type="date"
                              :clearable=false
                              placeholder="选择日期"
                              v-model="modifySampleInfoList.sampleTime"
                              style="width: 100%;"></el-date-picker>
            </el-form-item>
            <el-form-item v-if="showType==1"
                          label="产品名称">
              <el-select v-model="modifySampleInfoList.productId"
                         placeholder="">
                <el-option v-for="item in identityList"
                           :key="item.id"
                           :label="item.name"
                           :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item v-if="showType==1"
                          label="数据量"
                          prop="source">
              <el-input v-model="modifySampleInfoList.dataSize"
                        placeholder="请输入样本数据量"></el-input>
            </el-form-item>
            <el-form-item v-if="showType==1"
                          label="来源"
                          prop="source">
              <el-select v-model="modifySampleInfoList.source"
                         filterable
                         allow-create
                         default-first-option
                         placeholder="请选择样本来源">
                <el-option v-for="item in sourceList"
                           :key="item.id"
                           :label="item.name"
                           :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="样本批次"
                          key="type">
              <el-select size="medium"
                         v-model="modifySampleInfoList.batch"
                         placeholder="请选择类型">
                <el-option label="1"
                           value="1"></el-option>
                <el-option label="2"
                           value="2"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="备注"
                          prop="introduction">
              <el-input v-model="modifySampleInfoList.remarks"
                        type="textarea"
                        placeholder="请输入项目简介"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer"
              class="dialog-footer">
          <el-button v-if="identity==1||this.jurisdiction==3"
                     type='danger'
                     @click="delSample()">删除样本</el-button>
          <el-button @click="sampleInfo = false">取 消</el-button>
          <el-button type="success"
                     :disabled=modification
                     @click="modifySampleInfo()">提交修改</el-button>
        </span>
      </el-dialog>
    </template>

    <!-- 样本列表 -->
    <h1 class="sampleList">实验列表</h1>
    <el-divider></el-divider>
    <el-card class="box-card"
             v-for="(item,index)
             in
             sampleInfoData.experimentProcessListResList"
             :key="item.index">
      <div class="head">
        <div class="headline"
             @click="unfold(index)">{{item.name}} <i :class="conceal == index?'el-icon-arrow-up':' el-icon-arrow-down'"
             style="color:#409eff;font-weight:bold"></i></div>
      </div>
      <!-- 步骤条 -->
      <div class="step">
        <el-steps :active="item.finishExperimentCount"
                  finish-status="success">
          <el-step v-for="itemStep in item.experimentStageNameList"
                   :key="itemStep.index"
                   :title=itemStep></el-step>
        </el-steps>
      </div>
      <!-- 实验列表表格 -->
      <div v-show="conceal==index "
           v-for="(itemStage,index2) in item.experimentStageListResList"
           :key="index2">
        <div v-if="item.finishExperimentCount>=itemStage.step-1">
          <!-- 表格名称 -->
          <div class="title">
            <!-- 左 -->
            <div class="text">{{itemStage.name}}</div>
            <!-- 右 -->
            <div class="operation"
                 v-if="jurisdiction!=1">
              <el-button v-if="modifyFalg"
                         type="text"
                         icon="el-icon-tickets"
                         @click="write(itemStage.templateStageId,item.templateProcessId,itemStage.headAll)"
                         style="color:red;">编辑</el-button>
              <el-button v-if="!modifyFalg&&item.templateProcessId==templateProcessIdFlag&&itemStage.templateStageId==templateStageIdFlag"
                         type="text"
                         icon="el-icon-tickets"
                         @click="savestage(itemStage.dataList,index)"
                         style="color:red;">保存</el-button>
            </div>
          </div>
          <!-- 数据表格 -->
          <template>
            <el-table :data="itemStage.dataList"
                      style="width: 100%"
                      :header-cell-style="{background: '#4f71be',color: '#fff',}"
                      border
                      :fit='false'
                      size=mini>
              <template>
                <el-table-column v-for="(item3) in itemStage.headAll"
                                 :key="item3.index"
                                 :label="item3.name"
                                 :prop="item3.nameEnglish"
                                 resizable
                                 min-width=150px
                                 align="center"
                                 style="background-color:#e9ebf4;width:200px ;">
                  <template slot-scope="scope">
                    <el-button v-if="item3.nameEnglish=='operation'"
                               type="text"
                               size="small"
                               @click="addStage(scope.$index,index,index2)"><i class="el-icon-plus"></i>添加</el-button>
                    <el-button v-if="item3.nameEnglish=='operation'&&scope.row.Batch!=1"
                               type="text"
                               size="small"
                               @click="batchDelete(scope.$index,index,index2,scope.row.ExperimentStageId)"><i class="el-icon-delete"></i>删除</el-button>
                    <!-- 渲染普通文字 -->
                    <span v-if="item3.readonly">{{scope.row[item3.nameEnglish]}}
                    </span>
                    <!-- 点击编辑会变成input框的属性 -->
                    <span v-if="(modifyFalg&&!item3.readonly)&&item3.type==0">{{scope.row[item3.nameEnglish]}}
                    </span>
                    <!-- 控制其他行是否显示 -->
                    <span
                          v-if="(item.templateProcessId!=templateProcessIdFlag||itemStage.templateStageId!=templateStageIdFlag)&&!modifyFalg&&!item3.readonly&&!(scope.row[item3.nameEnglish]!=''&&item3.type==1)">{{scope.row[item3.nameEnglish]}}
                    </span>
                    <div v-if="scope.row[item3.nameEnglish]!=''&&item3.type==1">
                      <div style="position:relative;">
                        <el-image style="width: 50px; height: 50px"
                                  :preview-src-list=[$url+scope.row[item3.nameEnglish]]
                                  :src="$url+scope.row[item3.nameEnglish]">
                          <div slot="error"
                               class="image-slot">
                            <span class="dot"></span>
                          </div>
                        </el-image>
                        <div v-if="scope.row[item3.nameEnglish]!=undefined&&item3.type==1&&item.templateProcessId==templateProcessIdFlag&&itemStage.templateStageId==templateStageIdFlag&&!modifyFalg&&!item3.readonly"
                             style="position:absolute;right:5px;top:2px;"
                             @click="imageDelete(scope.$index,item3.nameEnglish)"><i class="el-icon-delete"></i></div>
                      </div>
                    </div>
                    <el-input v-if="item3.type==0&&item.templateProcessId==templateProcessIdFlag&&itemStage.templateStageId==templateStageIdFlag&&!modifyFalg&&!item3.readonly"
                              size="small"
                              :autosize="{ minRows: 1, maxRows: 5}"
                              type="textarea"
                              style='border:1px solid #fff;'
                              v-model="scope.row[item3.nameEnglish]" />
                    <el-upload v-if="item3.type==1&&item.templateProcessId==templateProcessIdFlag&&itemStage.templateStageId==templateStageIdFlag&&!modifyFalg&&!item3.readonly"
                               class="upload-demo"
                               ref="upload"
                               :headers="headers"
                               :data=recompose
                               name=image
                               action="api/spl/experiment/uploadExperimentImage"
                               :on-success="onsuccess"
                               :on-change="onchange(index,index2,item3.nameEnglish)"
                               multiple
                               :limit="1">
                      <el-button size="small"
                                 @click="getSubscript(scope.$index,scope.row.ExperimentStageId,item3.nameEnglish)"
                                 type="primary">本地上传</el-button>
                    </el-upload>
                    <el-button size="small"
                               @click="getSubscript(scope.$index,scope.row.ExperimentStageId,item3.nameEnglish,1)"
                               v-if="item3.type==1&&item.templateProcessId==templateProcessIdFlag&&itemStage.templateStageId==templateStageIdFlag&&!modifyFalg&&!item3.readonly">
                      复制上传</el-button>
                  </template>
                </el-table-column>
              </template>
            </el-table>
          </template>
        </div>
      </div>

    </el-card>
    <!-- 复制粘贴对话框 -->
    <template>
      <el-dialog title=""
                 :visible.sync="cv"
                 width="50%">
        <div class="box">
          <div id="preview"
               v-on:paste="handlePaste">
            <span>将图片按Ctrl+V 粘贴至此处</span>
          </div>
        </div>
        <span slot="footer"
              class="dialog-footer">
          <el-button @click="cv = false">取 消</el-button>
          <el-button type="success"
                     @click="uploadPlans()">提交</el-button>
        </span>
      </el-dialog>
    </template>
  </div>
</template>

<script>
import BaseTable from '@/components/BaseTable'
export default {
  components: {
    BaseTable
  },
  data() {
    return {
      cv: false,
      recompose: {
        stageId: ''
      },
      headers: {
        token: sessionStorage.getItem('token'),
        'Project-id': sessionStorage.getItem('encryptProjectId') || ''
      },
      rules: {
        templateProcessId: [{ required: true, message: '请选择流程模板', trigger: 'change' }]
      },
      // 控制展开隐藏
      conceal: 0,
      // 样本详情数据信息
      sampleInfoData: {
        projectSimpleInfoRes: {}
      },
      addExperimentalProcedure: false,
      addStep: false,
      // 添加实验流程入参
      addExperimentProcesslist: {
        templateProcessId: '',
        sampleIdArray: []
      },
      // 添加实验步骤入参
      addExperimentSteplist: {
        step: '',
        experimentProcessId: '',
        data: {}
      },
      // 查询实验步骤数据
      experimentStageTemplateList: [],
      // 修改样本信息入参
      modifySampleInfoList: {
        id: '',
        remarks: '',
        endTime: '',
        source: '',
        newId: '',
        sampleTime: '',
        customerName: '',
        productId: '',
        rateList: [],
        dataSize: '',
        batch: ''
      },
      stageNameList: [],
      modificationSample: false,
      pageNum: 1,
      pageSize: 50,
      sampleInfoId: '',
      // 实验流程数据
      experimentalProcedureData: [],
      // 禁用修改样本提交
      modification: false,
      // 禁用添加实验步骤提交
      openAdd: false,
      //编辑或保存
      modifyFalg: true,
      templateStageIdFlag: '',
      templateProcessIdFlag: '',
      savestageList: {
        stageData: {},
        delStageIdArray: []
      },
      // 编辑样本的数据
      compileSampleList: [],
      // 判断来自哪里
      type: 1,
      sampleSimpleInfoResList: [],
      location: [],
      subscript: '',
      identity: '',
      file: '',
      // 控制修改样本对话框
      sampleInfo: false,
      // 控制加载中
      loading: true,
      jurisdiction: '',
      projectId: '',
      // 产品名称
      identityList: [],
      sourceList: [],
      showType: '',
      // 表头
      tableColumnOptions: []
    }
  },
  mounted() {
    this.getProductList()
    this.getSourceList()
    this.identity = window.sessionStorage.getItem('identity')
    this.pageNum = this.$route.query.pageNum
    this.pageSize = this.$route.query.pageSize
    this.type = this.$route.query.type
    this.projectId = this.$route.query.projectId
    this.getProjectAuth(this.projectId).then(() => {
      if (this.$route.query.id != undefined) {
        window.sessionStorage.setItem('orderid', this.$route.query.id)
        var id = window.sessionStorage.getItem('orderid')
        this.sampleInfoId = id
        // 获取详情信息
        this.getSampleInfo(id)
      } else {
        var id = window.sessionStorage.getItem('orderid')
        this.sampleInfoId = id
        // 获取详情信息
        this.getSampleInfo(id)
      }
    })
  },

  methods: {
    // 获取产品名称列表
    async getProductList() {
      const { data: res } = await this.$http.get(`/spl/product/getProductList`)
      this.identityList = res.data
    },
    // 获取样本来源
    async getSourceList() {
      const { data: res } = await this.$http.get(`/spl/source/getSourceList`)
      this.sourceList = res.data
    },
    async getProjectAuth(_id) {
      const { data: res } = await this.$http.get(`/spl/projectAuth/getProjectAuth?projectId=${_id}`)
      this.jurisdiction = res.data.projectAuthValue
      window.sessionStorage.setItem('encryptProjectId', res.data.encryptProjectId)
    },
    handlePaste(event) {
      const items = (event.clipboardData || window.clipboardData).items
      let file = null
      if (!items || items.length === 0) {
        this.$message.error('当前浏览器不支持本地')
        return
      }
      // 搜索剪切板items
      for (let i = 0; i < items.length; i++) {
        if (items[i].type.indexOf('image') !== -1) {
          file = items[i].getAsFile()
          break
        }
      }
      if (!file) {
        this.$message.error('粘贴内容非图片')
        return
      }
      // 此时file就是我们的剪切板中的图片对象
      // 如果需要预览，可以执行下面代码
      const reader = new FileReader()
      reader.onload = (event) => {
        document.getElementById('preview').innerHTML = `<img style="width:100%;height:100%" src="${event.target.result}">`
      }
      reader.readAsDataURL(file)
      this.file = file
    },
    //上传文件成功后回调
    async uploadPlans() {
      let file = this.file
      if (!file) {
        this.$message.error('请粘贴图片后上传')
        return
      }
      // this.loading = true
      let form = new FormData()
      form.append('stageId', this.recompose.stageId)
      form.append('name', this.recompose.name)
      form.append('image', file)
      //uploadCertificate是封装的axios请求，自己根据需求传参
      const { data: res } = await this.$http.post(`/spl/experiment/uploadExperimentImage`, form)
      if (res.code != 200) {
        this.$message.error(response.msg)
      } else {
        this.sampleInfoData.experimentProcessListResList[this.location[0]].experimentStageListResList[this.location[1]].dataList[this.location[2]][this.location[3]] =
          res.data + `?t=${Date.parse(new Date())}`
        this.cv = false
        document.getElementById('preview').innerHTML = ''
        this.$message.success('上传成功！')
      }
    },
    // 添加实验流程多选
    handleSelectionChange(val) {
      this.addExperimentProcesslist.sampleIdArray = []
      val.forEach((item, index) => {
        this.addExperimentProcesslist.sampleIdArray.push(item.id)
      })
    },
    checkcolumn(headAll) {
      for (let index = 0; index < headAll.length; index++) {
        const element = headAll[index]
        if (element.nameEnglish == 'Batch') {
          return true
        }
      }
      return false
    },
    // 编辑
    write(templateStageId, templateProcessId, headAll) {
      if (this.checkcolumn(headAll)) {
        headAll.push({ id: 'operation', nameEnglish: 'operation', name: '操作' })
      }
      this.modifyFalg = !this.modifyFalg
      this.templateStageIdFlag = templateStageId
      this.templateProcessIdFlag = templateProcessId
    },
    // 保存
    async savestage(data, index) {
      this.modifyFalg = !this.modifyFalg
      this.templateStageIdFlag = ''
      this.templateProcessIdFlag = ''
      this.savestageList.stageData = JSON.stringify(data)
      const { data: res } = await this.$http.post(`/spl/experiment/modifyExperimentStage`, this.savestageList)
      if (res.code == 200 && res.data == 1) {
        this.$message.success('保存成功')
        this.getSampleInfo(this.sampleInfoId)
      } else {
        this.$message.error(res.msg)
        this.savestageList.delStageIdArray = []
      }
    },
    // 打开修改样本
    openModification(row) {
      this.compileSampleList = row.experimentProcessList
      this.modification = false
      this.modificationSample = true
    },
    // 判断是否展开
    unfold(index) {
      if (this.conceal == index) {
        this.conceal = -1
        return
      }
      this.conceal = index
    },
    // 添加实验流程模板选择
    experimentList(e) {
      this.stageNameList = this.experimentalProcedureData[e].stageNameList
    },
    // 点击编辑
    openSampleInfo(row) {
      this.modifySampleInfoList.newId = row.id
      this.modifySampleInfoList.id = row.id
      this.modifySampleInfoList.source = row.source
      this.modifySampleInfoList.remarks = row.remarks
      this.modifySampleInfoList.endTime = row.endTime
      this.modifySampleInfoList.rateList = row.rateList
      this.modifySampleInfoList.sampleTime = row.sampleTime
      this.modifySampleInfoList.customerName = row.customerName
      this.modifySampleInfoList.productId = row.productId
      this.modifySampleInfoList.dataSize = row.dataSize
      this.modifySampleInfoList.batch = row.batch
      this.sampleInfo = true
      this.modification = false
    },
    // 修改样本信息
    async modifySampleInfo() {
      if (this.modifySampleInfoList.customerName == '') {
        this.$message.error('客户名称不能为空，请重新输入！')
      } else {
        this.modification = true
        const { data: res } = await this.$http.post(`/spl/sample/modifySampleInfo`, this.modifySampleInfoList)
        if (res.code == 200 && res.data == 1) {
          this.$message.success('修改成功')
          if (this.modifySampleInfoList.newId != this.modifySampleInfoList.id) {
            this.back()
          }
        } else {
          this.$message.error(res.msg)
        }
        this.sampleInfo = false
        this.getSampleInfo(this.sampleInfoId)
      }
    },
    // 获取样本详情列表数据
    async getSampleInfo(id) {
      var sampleIdArray = []
      sampleIdArray = id.split(',')
      this.tableColumnOptions = []
      const { data: res } = await this.$http.post(`/spl/sample/getSampleInfo`, { sampleIdArray: sampleIdArray, projectId: this.projectId })
      this.sampleInfoData = res.data
      this.changeList()
      this.showType = res.data.projectSimpleInfoRes.type
      this.total = res.data.sampleSimpleInfoResList.length
      this.sampleInfoData.sampleSimpleInfoResList.forEach((item) => {
        if (item.rateList != null) {
          item.rateList.forEach((item2, index) => {
            if (index == item.rateList.length - 1) {
              item.rateList[index] = item2
            } else {
              item.rateList[index] = item2 + ' ' + '|' + ' '
            }
          })
        }
      })
      if (this.jurisdiction != 1) {
        res.data.sampleListColumnResList.push({ label: '操作', name: 'operation', columnType: true, soltName: 'operation', width: '180' })
      }
      res.data.sampleListColumnResList.forEach((item) => {
        if (item.name == 'customerNumber') {
          item.lineFeed = true
          item.columnType = true
          item.soltName = 'link'
          item.width = '130'
        } else if (item.name == 'remarks') {
          item.lineFeed = true
          item.columnType = true
          item.soltName = 'remarks'
        } else if (item.name == 'lungCancerResult') {
          item.lineFeed = true
          item.columnType = true
          item.soltName = 'lungCancerResult'
          item.width = '90'
          item.columnKey = 'lung'
        } else if (item.name == 'liverCancerResult') {
          item.width = '90'
          item.columnType = true
          item.soltName = 'liverCancerResult'
          item.columnKey = 'liver'
        } else if (item.name == 'isConfirm') {
          item.columnType = true
          item.soltName = 'isConfirm'
          item.columnKey = 'isConfirm'
        } else if (item.name == 'productName') {
          item.lineFeed = true
          item.columnType = true
          item.soltName = 'productName'
          item.filters = this.fileList
          item.columnKey = 'productName'
        }
        this.tableColumnOptions.push(item)
      })
      this.loading = false
    },
    // 返回
    back() {
      if (this.type == 1) {
        this.$router.push({ path: '/sample', query: { pageNum: this.pageNum, pageSize: this.pageSize } })
      } else {
        this.goOrderInfo(this.sampleInfoData.projectSimpleInfoRes.id)
      }
    },

    changeList() {
      this.sampleSimpleInfoResList = this.sampleInfoData.sampleSimpleInfoResList
    },
    // 跳转项目详情
    goOrderInfo(id) {
      this.$router.push({ path: '/project/projectPrticulars', query: { id: id, pageNumParticulars: this.pageNum, pageSizeParticulars: this.pageSize } })
    },
    // 点击添加实验流程
    addFlow() {
      this.addExperimentalProcedure = true
      this.getExperimentProcessTemplate()
    },
    // 添加实验流程
    addExperimentProcess(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          if (this.addExperimentProcesslist.sampleIdArray.length > 0) {
            this.addExperimentProcesslist.templateProcessId = this.experimentalProcedureData[this.addExperimentProcesslist.templateProcessId].id
            const { data: res } = await this.$http.post(`/spl/experiment/addExperimentProcess`, this.addExperimentProcesslist)
            if (res.code == 200) {
              this.$message.success('添加实验流程成功')
            } else {
              this.$message.error(res.msg)
            }
            this.addExperimentProcesslist.templateProcessId = ''
            this.stageNameList = []
            this.addExperimentalProcedure = false
            this.getSampleInfo(this.sampleInfoId)
          } else {
            this.$message.error('请选择你要勾选的样本添加实验流程')
          }
        } else {
          return false
        }
      })
    },
    // 查询实验流程模板
    async getExperimentProcessTemplate() {
      const { data: res } = await this.$http.get(`/spl/experiment/getExperimentProcessTemplate`)
      this.experimentalProcedureData = res.data
      this.sampleInfoData.sampleSimpleInfoResList.forEach((row) => {
        this.$refs.dataTable.toggleRowSelection(row, true)
      })
    },
    // 删除样本
    async delSample() {
      const msg = await this.$confirm('你确定要删除该样本吗?该操作会删除该样本的所有实验!', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
      if (msg === 'confirm') {
        const { data: res } = await this.$http.get(`/spl/sample/delSample?id=${this.modifySampleInfoList.id}`)
        //做删除操作
        if (res.code == 200) {
          this.back()
          this.$message.success('删除样本成功！')
        } else {
          this.$message.warning('删除样本失败')
        }
      }
      this.modificationSample = false
    },
    // 删除实验流程
    async delExperimentProcess(id, index) {
      const msg = await this.$confirm('你确定要删除该实验流程吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
      if (msg === 'confirm') {
        const { data: res } = await this.$http.get(`/spl/experiment/delExperimentProcess?id=${id}`)
        //做删除操作
        if (res.code == 200) {
          this.compileSampleList.splice(index, 1)
          this.$message.success('删除实验流程成功！')
        } else {
          this.$message.warning(res.msg)
        }
      }
      this.getSampleInfo(this.sampleInfoId)
    },
    // 点击失效
    async loseEfficacy(id) {
      const msg = await this.$confirm('你确定要失效该条数据吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
      if (msg === 'confirm') {
        const { data: res } = await this.$http.get(`/spl/experiment/invalidExperimentStage?experimentStageId=${id}`)
        if (res.code == 200) {
          this.$message.success('失效成功！')
        } else {
          this.$message.warning(res.msg)
        }
      }
      this.getSampleInfo(this.sampleInfoId)
    },
    // 获取表格图片的数组下标
    onchange(index, index1, nameEnglish) {
      this.location[0] = index
      // 获取当前表的位置
      this.location[1] = index1
      // 获取当前上传的行
      this.location[2] = this.subscript
      // 获取属性名
      // this.location[3] = nameEnglish
    },
    // 本地上传成功
    onsuccess(response) {
      if (response.code != 200) {
        this.$message.error(response.msg)
      } else if (this.location.length != 0) {
        this.sampleInfoData.experimentProcessListResList[this.location[0]].experimentStageListResList[this.location[1]].dataList[this.location[2]][this.location[3]] =
          response.data + `?t=${Date.parse(new Date())}`
      }
    },
    // 获取表格图片的下标和id
    getSubscript(row, id, name, type) {
      if (type == 1) {
        // 获取当前条数的下标
        this.subscript = row
        this.recompose.stageId = id
        this.recompose.name = name
        this.location[3] = name
        this.cv = true
      } else {
        this.subscript = row
        this.recompose.stageId = id
        this.recompose.name = name
        this.location[3] = name
      }
    },
    // 删除图片
    imageDelete(row, name) {
      this.location[2] = row
      this.location[3] = name
      this.sampleInfoData.experimentProcessListResList[this.location[0]].experimentStageListResList[this.location[1]].dataList[this.location[2]][this.location[3]] = ''
    },
    //添加步骤
    addStage(index, index1, index2) {
      var oldData = this.sampleInfoData.experimentProcessListResList[index1].experimentStageListResList[index2].dataList[index]
      var newData = {}
      newData.Batch = Number(oldData.Batch) + 1
      newData.SampleId = oldData.SampleId
      newData.ExperimentProcessId = oldData.ExperimentProcessId
      this.sampleInfoData.experimentProcessListResList[index1].experimentStageListResList[index2].dataList.splice(index + 1, 0, newData)
    },
    // 删除步骤
    batchDelete(index, index1, index2, id) {
      this.sampleInfoData.experimentProcessListResList[index1].experimentStageListResList[index2].dataList.splice(index, 1)
      this.savestageList.delStageIdArray.push(id)
    }
  }
}
</script>
<style lang='scss' scoped>
.sampleDetails {
  .project {
    .sample {
      display: flex;
      justify-content: space-between;
      .sampleLeft {
        width: 100%;
        .sampleID {
          margin-bottom: 20px;
          width: 100%;
          display: flex;
          .sampleCode {
            font-weight: bold;
            width: 50%;
            display: flex;
            .text-wrapper {
              word-break: break-all;
              word-wrap: break-word;
            }
          }
          .sampleType {
            font-weight: bold;
            display: flex;
            width: 50%;
          }
        }
        .sampleListMiddle {
          display: flex;
          justify-content: space-between;
        }
        .frame {
          margin: 10px 0px;
        }
      }
    }
    .projectTime {
      cursor: pointer;
      color: #0099cc;
      font-size: 20px;
    }

    .projectName {
      color: #0099cc;
      font-weight: bold;
      cursor: pointer;
    }
  }
  .demo-input-suffix1 {
    display: flex;
    .stageNameList {
      margin: 10px 0px 10px 50px;
    }
  }

  .sampleList {
    margin-top: 20px;
  }
  .box-card {
    margin: 20px 0px;
    .head {
      display: flex;
      justify-content: space-between;
      .headline {
        font-size: 20px;
        font-weight: bold;
      }
    }
    .step {
      margin: 20px 0px;
    }
    .title {
      display: flex;
      justify-content: space-between;
      margin: 10px 0px 10px 0px;
      .text {
        margin-top: 10px;
        font-weight: bold;
      }
      .operation {
        margin-right: 30px;
      }
    }
  }
  .box-card1 {
    margin: 20px 0px;
  }
  .box {
    width: 600px;
    height: 600px;
    #preview {
      width: 100%;
      height: 100%;
      border: 1px solid #000;
    }
  }
  .obvious {
    color: red;
  }
  ::v-deep .el-table .el-table__cell {
    padding: 4px 0;
  }
  ::v-deep .el-divider--horizontal {
    margin: 7px 0;
  }
  ::v-deep .el-dialog__body {
    padding: 10px 20px;
  }
  ::v-deep .el-table td.el-table__cell {
    border-bottom: 1px solid #ccc;
  }
}
</style>