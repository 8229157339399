import Vue from 'vue';
import VueRouter from 'vue-router';
Vue.use(VueRouter);

import login from '../views/login.vue';
import Home from '../views/Home.vue';
import Welcome from '../views/Welcome.vue';
// 用户管理信息
import info from '../views/management-center/info/info.vue';
import sample from '../views/management-center/sample/sample.vue';
import sampleInfo from '../views/management-center/sample/sampleInfo.vue';

// 项目管理
import project from '../views/management-center/project/project.vue';
import Prticulars from '../views/management-center/project/projectPrticulars.vue';
import userManagement from '../views/management-center/userManagement/userManagement.vue';
import processTemplates from '../views/management-center/templateManagement/processTemplates.vue';
import stepTemplate from '../views/management-center/templateManagement/stepTemplate.vue';
import setPermission from '../views/management-center/setPermission/setPermission.vue';
import product from '../views/management-center/product/product.vue';
import log from '../views/management-center/log/log.vue';
import source from '../views/management-center/source/source.vue';
const router = new VueRouter({
	routes: [
		{
			path: '/',
			redirect: (to) => {
				var tokenStr = window.sessionStorage.getItem('token');
				if (tokenStr == null) {
					return '/login';
				} else {
					return '/index';
				}
			}
		},
		{
			path: '/login',
			component: login,
			name: 'login',
			meta: { title: '登录' }
		},
		{
			path: '/',
			component: Home,
			name: 'Home',
			meta: { title: '首页' },
			children: [
				{ path: 'index', component: Welcome, name: 'Welcome', meta: { title: '首页' }, alias: '/index' },
				{ path: 'project', component: project, name: 'project', meta: { title: '项目管理' } },
				{ path: 'project/projectPrticulars', component: Prticulars, name: 'Prticulars', meta: { title: '项目详情' } },
				{ path: 'sample', component: sample, name: 'sample', meta: { title: '样本管理' } },
				{ path: 'userManagement', component: userManagement, name: 'userManagement', meta: { title: '用户管理' } },
				{ path: 'product', component: product, name: 'product', meta: { title: '产品管理' } },
				{ path: 'processTemplates', component: processTemplates, name: 'processTemplates', meta: { title: '流程模板管理' } },
				{ path: 'stepTemplate', component: stepTemplate, name: 'stepTemplate', meta: { title: '步骤模板管理' } },
				{ path: 'sampleInfo', component: sampleInfo, name: 'sampleInfo', meta: { title: '样本详情' } },
				{ path: 'info', component: info, name: 'info', meta: { title: '个人信息' } },
				{ path: 'log', component: log, name: 'log', meta: { title: '操作日志' } },
				{ path: 'source', component: source, name: 'source', meta: { title: '来源管理' } },
				{ path: 'setPermission', component: setPermission, name: 'setPermission', meta: { title: '分配权限' } }
			]
		},
		{ path: '*', redirect: '/index' }
	]
});

// 挂载路由导航守卫
router.beforeEach((to, from, next) => {
	// to 将要访问的路径
	// from 代表从哪个路径跳转而来
	// next 是一个函数， 表示放行   ①next()放行   ②next('/login')强制跳转
	const token = window.sessionStorage.getItem('token');
	if (to.path === '/login') {
		if (token != null) {
			console.log('token！=null');
			return next('/index');
		}
		console.log('访问登录直接放行');
		return next();
	} // 访问登录页，直接放行
	// 获取token

	// 如果token过期了
	if (!token) {
		if (to.path == '/login') return next();
		// 注意要import element的Message组件
		alert('登录状态过期，请重新登录');
		return next('/login');
		// 如果token没有过期，又是选择了登录页面就直接重定向到首页，不需要重新输入账户密码
	} else if (to.path == '/login') {
		return next('');
	}
	// if (!token) {
	// 	console.log('没有token强制跳转');
	// 	return next('/login'); // 没有token 强制跳转
	// }

	// 获取存储token的开始时间
	const tokenStartTime = window.sessionStorage.getItem('tokenStartTime');
	// 后台给出的token有效时间，一个星期 单位 是秒
	// 我们自己定义6个小时过期，让用户重新登录一下， 用户总不可能在一个页面挂机一天吧
	const timeOver = 21600000; //21600000
	// console.log(timeOver);
	// 当前时间
	let date = new Date().getTime();
	// 如果大于说明是token过期了
	if (date - tokenStartTime > timeOver) {
		sessionStorage.removeItem('token');
		token = null;
	}
	next(); // 否则直接放行
});

export default router;
