<!--操作日志  -->
<template>
  <div>
    <el-form size="small"
             :inline="true"
             label-width="68px">
      <el-form-item label="样本编号">
        <el-input siz="medium"
                  v-model="sampleId"
                  clearable
                  placeholder="请输入样本编号"
                  @keyup.enter='getSampleOperationLog()'></el-input>
      </el-form-item>
      <el-button type="primary"
                 size="medium"
                 icon="el-icon-search"
                 @click="getSampleOperationLog">搜索</el-button>
      </el-form-item>
    </el-form>
    <!-- 数据表格 -->
    <template>
      <el-table :data="tableData"
                align="center"
                style="width: 100%"
                :header-cell-style="{background: '#4f71be',color: '#fff'}">
        <el-table-column prop="module"
                         align="center"
                         label="模块名称"></el-table-column>
        <el-table-column prop="contentList"
                         align="center"
                         width="1000"
                         label="操作内容">
          <template slot-scope="scope">
            <div v-for="item in scope.row.contentList ">
              <span v-html=tableRowClassName(item)></span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="operationTime"
                         align="center"
                         label="操作时间"></el-table-column>
        <el-table-column prop="username"
                         align="center"
                         label="操作者"></el-table-column>

      </el-table>
    </template>

  </div>

</template>

<script>
export default {
  data() {
    return {
      sampleId: '',
      tableData: []
    }
  },
  methods: {
    async getSampleOperationLog() {
      const { data: res } = await this.$http.get(`/spl/operation/getSampleOperationLog?sampleId=${this.sampleId}`)
      if (res.code != 200) {
        this.$message.error(res.msg)
      }
      this.tableData = res.data
    },
    tableRowClassName(row) {
      var result = row.replace(/([^:]+):/g, function (match, p1) {
        return '<b>' + p1 + '</b>:'
      })
      return result
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
